import { autoEffect, view } from "@risingstack/react-easy-state";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";

import { auth } from "./stores/auth";
import { notifications } from "./stores/notifications";

import Login from "./routes/Auth/Login";
import Registration from "./routes/Auth/Registration";
import ForgotPassword from "./routes/Auth/ForgotPassword";
import ResetPassword from "./routes/Auth/ResetPassword";
import Dashboard from "./routes/Dashboard/Dashboard";
import DeviceDetails from "./routes/Dashboard/DeviceDetails";
import YearDetails from "./routes/Dashboard/YearDetails";
import Bills from "./routes/Bills/Bills";
import BillDetails from "./routes/Bills/BillDetails";
import PaySuccess from "./routes/Bills/PaySuccess";
import PayCancel from "./routes/Bills/PayCancel";
import Info from "./routes/Info/Info";
import Notifications from "./routes/Notifications/Notifications";
import NotificationDetails from "./routes/Notifications/NotificationDetails";
import Profile from "./routes/Profile/Profile";

import ProtectedRoute from "./parts/ProtectedRoute";
import Footer from "./parts/Footer/Footer";
import Sidebar from "./parts/Sidebar/Sidebar";
import Spinner from "./parts/Spinner/Spinner";
import NotFound from "./parts/PageMessage/NotFound";

import {
  WrapperSC,
  InnerWrapperSC,
  MainSC,
  LoadingUserSpinnerSC,
} from "./parts/styled";
import PrivacyPolicy from "./routes/Privacy/PrivacyPolicy";

const App = view(() => {
  const navigate = useNavigate();
  const location = useLocation();

  autoEffect(async () => {
    await auth.getUser();
    if (!auth.isLoggedIn) {
      if (
        location.pathname !== "/registracija" &&
        location.pathname !== "/info" &&
        location.pathname !== "/zaboravljena-lozinka" &&
        location.pathname !== "/reset-lozinke"
      )
        navigate("/prijava");
    }
  });

  autoEffect(async () => {
    if (auth.isLoggedIn) {
      await notifications.getNotifications();
    }
  });

  return (
    <>
      <WrapperSC>
        <>
          {auth.isLoadingUser ? (
            <LoadingUserSpinnerSC>
              <Spinner />
            </LoadingUserSpinnerSC>
          ) : (
            <>
              <Sidebar />
              <InnerWrapperSC>
                <MainSC>
                  <Routes>
                    <Route
                      path="/politika-privatnosti"
                      element={<PrivacyPolicy />}
                    />
                    <Route path="/prijava" element={<Login />} />
                    <Route path="/registracija" element={<Registration />} />
                    <Route
                      path="/zaboravljena-lozinka"
                      element={<ForgotPassword />}
                    />
                    <Route path="/reset-lozinke" element={<ResetPassword />} />
                    <Route path="/info" element={<Info />} />
                    <Route path="/" element={<ProtectedRoute />}>
                      <Route path="/" element={<Dashboard />}>
                        <Route
                          path="/vodomjeri/:deviceId"
                          element={<DeviceDetails />}
                        >
                          <Route path=":year" element={<YearDetails />} />
                        </Route>
                      </Route>
                      <Route path="/racuni" element={<Bills />}>
                        <Route
                          path="/racuni/:billId"
                          element={<BillDetails />}
                        />
                      </Route>
                      <Route
                        path="/uspjesno-placanje"
                        element={<PaySuccess />}
                      />
                      <Route
                        path="/ponisteno-placanje"
                        element={<PayCancel />}
                      />
                      <Route path="/obavijesti" element={<Notifications />}>
                        <Route
                          path="/obavijesti/:notificationId"
                          element={<NotificationDetails />}
                        />
                      </Route>
                      <Route path="/profil" element={<Profile />} />
                      <Route
                        path="*"
                        element={
                          <NotFound message="Stranica nije pronađena." />
                        }
                      />
                    </Route>
                  </Routes>
                </MainSC>
                <Footer />
              </InnerWrapperSC>
            </>
          )}
        </>
      </WrapperSC>
    </>
  );
});

export default App;
