import { store } from "@risingstack/react-easy-state";
import { API_ENDPOINT } from "../config/constants.js";
import { authHeader } from "./auth";

export const measurements = store({
  isLoadingPost: false,
  isPostSuccessfull: false,

  async postMeasurement(id, amount) {
    measurements.isLoadingPost = true;

    try {
      const response = await fetch(
        `${API_ENDPOINT}/system/user-measurement/${id}`,
        {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            ...authHeader(),
          },
          body: JSON.stringify({ measurement_amount: amount }),
        }
      );

      if (!response.ok) {
        throw Error(response.statusText);
      }

      await response.json();

      measurements.isPostSuccessfull = true;
      measurements.isLoadingPost = false;
    } catch (error) {
      measurements.isLoadingPost = false;
      console.log(error);
    }
  },
});
