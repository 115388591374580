import styled from "styled-components";

import { Box, Icon } from "@mui/material";

import LoginIconSvg from "../../assets/img/login_icon.svg";
import KeyIconSvg from "../../assets/img/key_icon.svg";
import MailSendImageSvg from "../../assets/img/success_mail.svg";
import ArrowBackIconSvg from "../../assets/img/arrow_back_icon.svg";
import InfoLabelIconSvg from "../../assets/img/info_circle_icon.svg";
import InfoIconSvg from "../../assets/img/info_icon.svg";

export const WrapperSC = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 52px;

  &.registration {
    min-height: 800px;
    overflow-y: auto;

    @media (max-width: 600px) {
      min-height: 1100px;
    }
  }

  @media (max-width: 1024px) {
    align-items: start;
  }
`;

export const ContentSC = styled(Box)`
  width: 370px;
  overflow-y: hidden;

  h1 {
    font-family: ${({ theme }) => theme.fonts.b400};
    font-weight: 400;
    font-size: 28px;
    line-height: 36px;
    letter-spacing: 0.56px;
    color: ${({ theme }) => theme.palette.primary800};
    margin-bottom: 20px;
  }
`;

export const TextSC = styled.p`
  font-family: ${({ theme }) => theme.fonts.b400};
  font-size: 14px;
  line-height: 24px;
  color: ${({ theme }) => theme.palette.primary800};
  margin-bottom: 40px;

  a {
    font-family: ${({ theme }) => theme.fonts.b700};
    color: ${({ theme }) => theme.palette.primary500};
  }
`;

export const IconLoginSC = styled(Icon)`
  &.MuiIcon-root {
    width: 20px;
    height: 18px;
    -webkit-mask: url(${LoginIconSvg}) no-repeat center;
    mask: url(${LoginIconSvg}) no-repeat center;
    margin-left: 24px;
  }
`;

export const IconKeySC = styled(Icon)`
  &.MuiIcon-root {
    width: 22px;
    height: 12px;
    -webkit-mask: url(${KeyIconSvg}) no-repeat center;
    mask: url(${KeyIconSvg}) no-repeat center;
  }
`;

export const LoginTitleWrapperSC = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const IconInfoSC = styled(Icon)`
  margin-left: 24px;
  margin-bottom: 20px;
  &.MuiIcon-root {
    width: 17px;
    height: 20px;
    -webkit-mask: url(${InfoIconSvg}) no-repeat center;
    mask: url(${InfoIconSvg}) no-repeat center;
    background-color: ${({ theme }) => theme.palette.primary800};

    :hover {
      cursor: pointer;
    }
  }
`;

export const IconInfoLabelSC = styled(Icon)`
  &.MuiIcon-root {
    width: 13px;
    height: 13px;
    -webkit-mask: url(${InfoLabelIconSvg}) no-repeat center;
    mask: url(${InfoLabelIconSvg}) no-repeat center;
    mask-size: cover;
    background-color: ${({ theme }) => theme.palette.primary800};
    margin-bottom: 8px;
  }
`;

export const LabelIconBoxSC = styled(Box)`
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 24px;
`;

export const LinkBackWrapperSC = styled(Box)`
  a {
    font-family: ${({ theme }) => theme.fonts.b700};
    font-size: 14px;
    line-height: 24px;
    color: ${({ theme }) => theme.palette.primary500};
  }
`;

export const IconMailSendSC = styled.img.attrs({
  src: MailSendImageSvg,
})`
  width: 108.6px;
  height: 96px;
  margin-bottom: 40px;
`;

export const IconArrowBackSC = styled(Icon)`
  &.MuiIcon-root {
    width: 16px;
    height: 16px;
    -webkit-mask: url(${ArrowBackIconSvg}) no-repeat center;
    mask: url(${ArrowBackIconSvg}) no-repeat center;
  }
`;

export const FormFooterSC = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
