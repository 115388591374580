import styled from "styled-components";

import { Icon, Box } from "@mui/material";

import NoDataImageSvg from "../../assets/img/no_data_image.svg";
import GeneralErrorImageSvg from "../../assets/img/general_error_image.svg";

export const MessageWrapperSC = styled(Box)`
  position: ${({ variant }) =>
    variant === "relative" ? "relative" : "absolute"};
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 60px 0;

  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  p {
    font-family: ${({ theme }) => theme.fonts.b400};
    font-weight: 400;
    margin-top: 24px;
    font-size: 12px;
    color: ${({ theme }) => theme.palette.primary900};
    text-align: center;
  }
`;

export const IconNoDataSC = styled(Icon)`
  &.MuiIcon-root {
    width: 160px;
    height: 160px;
    background: url(${NoDataImageSvg}) no-repeat center;
    background-color: transparent;
  }
`;

export const IconGeneralErrorSC = styled(Icon)`
  &.MuiIcon-root {
    width: 160px;
    height: 160px;
    background: url(${GeneralErrorImageSvg}) no-repeat center;
    background-color: transparent;
  }
`;
