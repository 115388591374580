import { store } from "@risingstack/react-easy-state";
import { API_ENDPOINT } from "../config/constants.js";
import { auth, authHeader } from "./auth";
// import { generateNotifications } from "../routes/Notifications/mockup.js";

export const notifications = store({
  allNotifications: null,
  notification: null,
  isLoadingGet: false,
  isLoadingReadAll: false,
  isLoadingDelete: false,
  isReadAllSuccessfull: false,
  isReadSingleSuccessfull: false,
  isDeleteAllSuccessfull: false,

  async getNotifications() {
    auth.isLoadingGet = true;
    try {
      const response = await fetch(
        `${API_ENDPOINT}/system/user-notifications`,
        {
          method: "GET",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            ...authHeader(),
          },
        }
      );

      if (!response.ok) {
        throw Error(response.statusText);
      }

      const responseJson = await response.json();
      notifications.allNotifications = responseJson.data;
      notifications.isLoadingGet = false;
    } catch (error) {
      notifications.isLoadingGet = false;
      console.log(error);
    }
  },

  get unreadCount() {
    if (notifications.allNotifications?.length > 0) {
      return notifications.allNotifications.filter(n => !n.read_at).length;
    } else {
      return 0;
    }
  },

  async readNotifications() {
    notifications.isLoadingReadAll = true;
    try {
      const response = await fetch(
        `${API_ENDPOINT}/system/read-notifications`,
        {
          method: "PATCH",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            ...authHeader(),
          },
        }
      );

      if (!response.ok) {
        throw Error(response.statusText);
      }
      await response.json();

      notifications.isReadAllSuccessfull = true;
      notifications.isLoadingReadAll = false;
    } catch (error) {
      notifications.isReadAllSuccessfull = false;
      notifications.isLoadingReadAll = false;;
      console.log(error);
    }
  },

  async readNotification(id) {
    try {
      const response = await fetch(
        `${API_ENDPOINT}/system/read-notification/${id}`,
        {
          method: "PATCH",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            ...authHeader(),
          },
        }
      );

      if (!response.ok) {
        throw Error(response.statusText);
      }
      await response.json();

      notifications.isReadSingleSuccessfull = true;
    } catch (error) {
      notifications.isReadSingleSuccessfull = false;
      console.log(error);
    }
  },
});
