import { view } from "@risingstack/react-easy-state";

import { Drawer as DrawerMUI } from "@mui/material";

const Drawer = view(({ children, breakpoint, ...rest }) => {
  if (breakpoint) {
    return <DrawerMUI {...rest}>{children}</DrawerMUI>;
  } else {
    return children;
  }
});

export default Drawer;
