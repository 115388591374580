import { view } from "@risingstack/react-easy-state";

import { devices } from "../../stores/devices";

import Toggler from "../../parts/Toggler/Toggler";

import { OnlineBillBoxSC } from "./styled";

const OnlineBill = view(() => {
  const handleChangeToggler = async () => {
    await devices.updateDevice(devices.device.id, !devices.device.e_bill);
    if (devices.isUpdateSuccessfull) {
      devices.device.e_bill = !devices.device.e_bill;
    }
    devices.isUpdateSuccessfull = false;
  };

  return (
    <>
      {devices.device && (
        <OnlineBillBoxSC>
          <div>
            <h3 onClick={handleChangeToggler}>Online račun</h3>
            <p>
              {devices.device.e_bill
                ? "Hvala Vam što brinete za okoliš!"
                : "Aktivirajte opciju online dostave računa"}
            </p>
          </div>
          <div>
            <Toggler
              checked={Boolean(devices.device.e_bill)}
              onChange={handleChangeToggler}
              disabled={devices.isLoadingUpdate}
            />
          </div>
        </OnlineBillBoxSC>
      )}
    </>
  );
});

export default OnlineBill;
