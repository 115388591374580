import { view } from "@risingstack/react-easy-state";

import { ModalSC, ModalBackdropSC, ModalBoxSC, ModalTitleSC } from "./styled";

const Modal = view(({ children, title, ...rest }) => {
  return (
    <ModalSC {...rest} BackdropComponent={ModalBackdropSC}>
      <ModalBoxSC>
        <ModalTitleSC>{title}</ModalTitleSC>
        {children}
      </ModalBoxSC>
    </ModalSC>
  );
});

export default Modal;
