import { useEffect, useState } from "react";
import { view, autoEffect } from "@risingstack/react-easy-state";
import { Link, useParams, useNavigate } from "react-router-dom";
import { format } from "date-fns";

import useMediaQuery from "@mui/material/useMediaQuery";

import { devices } from "../../stores/devices";
import { formatChartData } from "./utils";

import Chart from "./Chart";
import Number from "../../parts/Number/Number";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  BillsSectionSC,
  ChartInfoSC,
  ChartInfoTextSC,
  ChartSectionSC,
  GraphicSectionSC,
  IconCancelSC,
  IconChartInfoCircleSC,
  IconCheckSC,
  IconClockSC,
  IconHomeSC,
  IconLeaderboardSC,
  IconPendingActionsSC,
  StatisticCardSC,
  StatisticSectionSC,
} from "./styled";

const YearDetails = view(() => {
  const navigate = useNavigate();
  const { deviceId } = useParams();
  const { year } = useParams();
  const [chartData, setChartData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const matches600Up = useMediaQuery("(min-width:600px)");
  const matches501Up = useMediaQuery("(min-width:501px)");

  autoEffect(() => {
    devices.yearData = null;
    if (devices.device && devices.yearlyBills?.length > 0) {
      devices.yearData = devices.yearlyBills.find(
        (y) => y.bills[0].reading_date.substring(0, 4) === year
      );
    }
    if (!devices.yearData && devices.device) {
      navigate(`/vodomjeri/${deviceId}`);
    }
    if (devices.yearData && devices.device) {
      setChartData(formatChartData(devices.yearData.bills, year));
      setTableData(devices.yearData.bills);
    }
  }, [year, deviceId]);

  useEffect(() => {
    return () => {
      devices.yearData = null;
    };
  }, []);

  const handleRowClick = (item) => {
    if (!matches501Up && item.bill_state !== "pending") {
      navigate(`/racuni/${item.id}`, { redirectId: item.id });
    }
  };

  return (
    <>
      {year && (
        <>
          <GraphicSectionSC>
            <ChartSectionSC>
              <ChartInfoSC>
                <IconChartInfoCircleSC />
                <ChartInfoTextSC>
                  Prikazana potrošnja je isključivo informativnog karaktera i
                  može se razlikovati od obračunate potrošnje.
                </ChartInfoTextSC>
              </ChartInfoSC>
              {chartData?.length > 0 && <Chart data={chartData} />}
            </ChartSectionSC>
            <StatisticSectionSC>
              <div>
                <StatisticCardSC bg="dark">
                  <IconHomeSC />
                  <p>Prosječna potrošnja</p>
                  <footer>
                    <h3>
                      <Number
                        className="num-small"
                        value={devices.yearData?.average_monthly_consumption}
                        decimalScale={3}
                      />
                      <span>
                        m<sup>3</sup>
                      </span>
                    </h3>
                  </footer>
                </StatisticCardSC>
                <StatisticCardSC>
                  <IconLeaderboardSC />
                  <p>Prosječna dnevna potrošnja</p>
                  <footer>
                    <h3>
                      <Number
                        className="num-small"
                        value={devices.yearData?.average_daily_consumption}
                        decimalScale={3}
                      />
                      <span>
                        m<sup>3</sup>
                      </span>
                    </h3>
                  </footer>
                </StatisticCardSC>
              </div>
              <StatisticCardSC bg="dark" size="large">
                <IconPendingActionsSC />
                <p>Stanje vodomjera kod zadnjeg očitanja</p>
                <footer>
                  <h3>
                    <Number
                      className="num-large"
                      value={devices.yearData?.latest_consumption}
                      decimalScale={0}
                    />
                    <span style={{ marginBottom: "4px" }}>
                      m<sup>3</sup>
                    </span>
                  </h3>
                </footer>
              </StatisticCardSC>
            </StatisticSectionSC>
          </GraphicSectionSC>
          <BillsSectionSC>
            <h2>Računi</h2>
            {tableData?.length > 0 && (
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell>Datum očitanja</TableCell>
                      <TableCell>Stanje brojila</TableCell>
                      {matches600Up && <TableCell>Datum dospijeća</TableCell>}
                      <TableCell>Potrošnja</TableCell>
                      <TableCell>Iznos EUR</TableCell>
                      <TableCell>Iznos HRK</TableCell>
                      {matches501Up && <TableCell></TableCell>}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tableData
                      .map((item) => (
                        <TableRow
                          key={item.id}
                          onClick={() => handleRowClick(item)}
                          sx={
                            item.bill_state !== "pending" && !matches501Up
                              ? {
                                  cursor: "pointer",
                                }
                              : null
                          }
                        >
                          <TableCell
                            sx={
                              matches501Up
                                ? { width: "40px" }
                                : { width: "30px" }
                            }
                          >
                            {item.bill_state === "paid" ? (
                              <IconCheckSC />
                            ) : item.bill_state === "unpaid" ? (
                              <IconCancelSC />
                            ) : (
                              <IconClockSC />
                            )}
                          </TableCell>
                          <TableCell>
                            {item.reading_date &&
                              format(
                                new Date(item.reading_date),
                                matches501Up ? "d.M.yyyy." : "d.M."
                              )}
                          </TableCell>
                          <TableCell>
                            <Number
                              className="eur-num"
                              value={item.water_gauge}
                              decimalScale={0}
                            />
                          </TableCell>
                          {matches600Up && (
                            <TableCell>
                              {item.bill_date &&
                                format(new Date(item.bill_date), "d.M.yyyy.")}
                            </TableCell>
                          )}
                          <TableCell>
                            <Number
                              className="eur-num"
                              value={item.bill_consumption}
                              decimalScale={3}
                            />
                          </TableCell>
                          <TableCell>
                            <Number
                              className="eur-num"
                              value={item.bill_amount_euro}
                              decimalScale={2}
                            />
                          </TableCell>
                          <TableCell>
                            <Number
                              className="eur-num"
                              value={item.bill_amount_hrk}
                              decimalScale={2}
                            />
                          </TableCell>
                          {matches501Up && (
                            <TableCell align="right">
                              {item.bill_date && (
                                <Link
                                  to={`/racuni/${item.id}`}
                                  state={{ redirectId: item.id }}
                                >
                                  Detalji
                                </Link>
                              )}
                            </TableCell>
                          )}
                        </TableRow>
                      ))
                      .reverse()}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </BillsSectionSC>
        </>
      )}
    </>
  );
});

export default YearDetails;
