import styled from "styled-components";

import { Box, Button, Icon, Paper } from "@mui/material";

import RuleIconSvg from "../../assets/img/rule_icon.svg";
import DownloadIconSvg from "../../assets/img/download_icon.svg";
import PaymentIconSvg from "../../assets/img/payment_icon.svg";
import ArrowBackSvg from "../../assets/img/arrow_back_icon.svg";
import PaySuccessSvg from "../../assets/img/success_pay.svg";

// Bills
// Bill Details

//
//
// ---------------------------------------------------------------
// --------------------------- Bills -----------------------------
// ---------------------------------------------------------------
export const PageWrapperSC = styled(Box)`
  display: flex;
  width: 100%;
  height: 100%;
`;

export const LeftPageSideSC = styled(Box)`
  width: 392px;
  height: calc(100% - 296px);

  @media (max-width: 1024px) {
    height: calc(100% - 360px);
    margin-top: 64px;
  }

  @media (max-width: 500px) {
    height: calc(100% - 296px);
    margin-top: 0px;
    width: 100%;
  }
`;

export const TitleSC = styled.h1`
  font-family: ${({ theme }) => theme.fonts.h600};
  color: ${({ theme }) => theme.palette.primary800};
  font-size: 32px;
  line-height: 40px;
  letter-spacing: 0.56px;
  margin: 40px 0;
  padding-left: 24px;
`;

export const BillListSettingsSC = styled(Box)`
  top: 120px;
  width: 100%;
  height: 176px;
  padding: 0 24px;
`;

export const TopPartSC = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: start;

  button {
    margin-top: 20px;
  }
`;

export const IconRuleSC = styled(Icon)`
  &.MuiIcon-root {
    width: 20px;
    height: 15px;
    -webkit-mask: url(${RuleIconSvg}) no-repeat center;
    mask: url(${RuleIconSvg}) no-repeat center;
    background-color: ${({ theme }) => theme.palette.neutral100};
  }
`;

export const TotalDebtSC = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;

  & > p {
    text-align: right;
    margin-bottom: 4px;
    font-family: ${({ theme }) => theme.fonts.h700};
    color: ${({ theme }) => theme.palette.primary900};
    font-size: 10px;
    line-height: 16px;
  }

  div {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    p.hrk {
      display: flex;
      align-items: end;

      .hrk-num {
        font-family: ${({ theme }) => theme.fonts.h700};
        color: ${({ theme }) => theme.palette.primary500};
        font-size: 28px;
        line-height: 36px;
      }

      .unpaid {
        color: ${({ theme }) => theme.palette.danger500};
      }

      span {
        line-height: 24px;
        font-size: 12px;
        font-family: ${({ theme }) => theme.fonts.h700};
        color: ${({ theme }) => theme.palette.primary900};
        margin-left: 4px;
      }
    }

    p.eur {
      display: flex;
      align-items: end;
      margin-right: -6px;

      .eur-num {
        font-family: ${({ theme }) => theme.fonts.h700};
        color: #8b8a8d;
        font-size: 18px;
        line-height: 24px;
      }

      span {
        font-family: ${({ theme }) => theme.fonts.h700};
        font-size: 10px;
        line-height: 19px;
        color: #8b8a8d;
        margin-left: 4px;
      }

      span.bracket {
        font-size: 18px;
        line-height: 24px;
        color: #8b8a8d;
        font-family: ${({ theme }) => theme.fonts.h500};
      }
    }
  }
`;

export const BottomPartSC = styled(Box)`
  display: flex;
  justify-content: space-between;
  margin-top: 36px;
`;

export const FilterButtonSC = styled(Button)`
  &.MuiButton-root {
    background-color: ${({ theme, selected }) =>
      selected ? theme.palette.primary500 : theme.palette.primary50};
    border-radius: 50px;
    border: ${({ theme, selected }) =>
      selected
        ? `1px solid ${theme.palette.primary500}`
        : `1px solid ${theme.palette.primary100}`};
    padding: 12px 20px;
    color: ${({ theme, selected }) =>
      selected ? theme.palette.neutral100 : theme.palette.primary500};
    font-family: ${({ theme }) => theme.fonts.b700};
    font-size: 10px;
    line-height: 16px;
    text-transform: none;
    box-shadow: ${({ selected }) =>
      selected ? "none" : "0px 4px 8px 1px rgba(0, 186, 255, 0.1)"};
    &:hover {
      border: ${({ theme }) => `1px solid ${theme.palette.primary500}`};
      background-color: ${({ theme }) => theme.palette.primary500};
      color: ${({ theme }) => theme.palette.primary50};
      box-shadow: none;
    }
  }
`;

export const BillListSC = styled(Box)`
  margin: 0;
  padding-left: 24px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #e5e4e8;
    border: none;
  }

  // Firefox
  scrollbar-color: #e5e4e8 transparent;
`;

export const RightPageSideSC = styled(Box)`
  flex: 1;
  overflow: auto;
  padding: 0 24px;
`;

export const RightPageSideContainerSC = styled(Box)`
  max-width: 1200px;
  margin: auto;
`;

// Bill Card
export const BillCardSC = styled(Paper)`
  margin-bottom: 20px;
  &.MuiPaper-root {
    cursor: pointer;
    padding: 20px 32px;
    background-color: ${({ theme }) => theme.palette.neutral0};
    border: 1px solid #eeeff9;
    border-radius: 8px;
    box-shadow: 0px 8px 24px -8px rgba(34, 3, 101, 0.1);
    width: 344px;

    @media (max-width: 500px) {
      width: calc(100vw - 48px);
    }
  }

  &.active {
    &.MuiPaper-root {
      user-select: text;
      -webkit-user-drag: none;
      background-color: ${({ theme }) => theme.palette.primary50};
    }

    header h3 {
      color: ${({ theme }) => theme.palette.primary500};
    }
  }

  header {
    margin-bottom: 24px;
    div {
      display: flex;
      justify-content: space-between;
      margin-bottom: 2px;
    }
    h3 {
      font-family: ${({ theme }) => theme.fonts.b700};
      color: ${({ theme }) => theme.palette.primary800};
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.32px;
    }
  }
`;

export const BodySC = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const DateAndButtonSC = styled.div`
  p {
    font-family: ${({ theme }) => theme.fonts.h500};
    color: #333;
    font-size: 10px;
    line-height: 16px;
    margin-bottom: 8px;
    text-align: center;
  }
`;

export const TotalPriceSC = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: ${({ theme }) => theme.fonts.h700};
  color: ${({ theme }) => theme.palette.primary900};
  font-size: 10px;
  line-height: 16px;

  & > p {
    text-align: right;
    margin-bottom: 4px;
  }

  div {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    p.hrk {
      .hrk-num {
        color: ${({ theme }) => theme.palette.primary500};
        font-size: 18px;
        line-height: 20px;
        margin-right: 4px;
      }

      .unpaid {
        color: ${({ theme }) => theme.palette.danger500};
      }

      span {
        font-size: 12px;
        line-height: 20px;
        color: ${({ theme }) => theme.palette.primary900};
      }
    }

    p.eur {
      margin-bottom: 0;
      margin-right: -4px;
      .eur-num {
        color: #8b8a8d;
        font-size: 12px;
        line-height: 20px;
        margin-right: 4px;
      }

      span {
        font-size: 10px;
        line-height: 20px;
        color: #8b8a8d;
      }

      span.bracket {
        font-size: 12px;
        line-height: 20px;
        color: #8b8a8d;
        margin: 0 4px;

        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }
`;

export const ButtonBillsBackSC = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: none;
  outline: none;
  font-family: ${({ theme }) => theme.fonts.b400};
  color: ${({ theme }) => theme.palette.primary900};
  font-size: 18px;
  line-height: 24px;
  padding: 0;
  margin: 48px 0 8px;
  cursor: pointer;
`;

export const ArrowBackSC = styled(Icon)`
  margin-right: 12px;
  &.MuiIcon-root {
    width: 16px;
    height: 16px;
    -webkit-mask: url(${ArrowBackSvg}) no-repeat center;
    mask: url(${ArrowBackSvg}) no-repeat center;
    background-color: ${({ theme }) => theme.palette.primary900};
  }
`;

//
//
// ---------------------------------------------------------------
// ---------------------- Bill Details ---------------------------
// ---------------------------------------------------------------

export const TitleBillSC = styled.h2`
  font-family: ${({ theme }) => theme.fonts.b400};
  font-weight: 400;
  font-size: 28px;
  line-height: 36px;
  letter-spacing: 0.56px;
  color: ${({ theme }) => theme.palette.primary900};
  margin-top: 120px;
  margin-bottom: 40px;

  @media (max-width: 1024px) {
    margin-top: 40px;
  }
`;

export const OnlineBillBoxSC = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 16px;
  border-bottom: 1px solid #e5e4e8;

  h3 {
    font-family: ${({ theme }) => theme.fonts.b700};
    display: inline-block;
    font-size: 12px;
    line-height: 24px;
    color: #313132;
    cursor: pointer;
  }

  p {
    font-family: ${({ theme }) => theme.fonts.b400};
    font-size: 14px;
    line-height: 24px;
    color: #313132;
  }
`;

export const AddressSectionSC = styled(Box)`
  h3 {
    font-family: ${({ theme }) => theme.fonts.b700};
    font-size: 12px;
    line-height: 24px;
    color: #313132;
  }

  p {
    font-family: ${({ theme }) => theme.fonts.b400};
    font-size: 14px;
    line-height: 24px;
    color: #313132;
  }
`;

export const AddressSC = styled(Box)`
  display: flex;
  margin: 15px 0;

  & > div {
    flex: 1;
  }
`;

export const SummarySC = styled(Box)`
  background-color: ${({ theme }) => theme.palette.neutral100};
  width: 100%;
  display: flex;
  align-items: start;
  justify-content: space-between;
  padding: 0 16px;
  border-radius: 8px;
  margin: 40px 0 0 0;
  padding: 24px 16px 16px;
`;

export const ConversionSC = styled(Box)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 8px 16px;
  margin-bottom: 40px;
`;

export const ConversionTextSC = styled.p`
  color: #8b8a8d;
  font-size: 12px;
  line-height: 20px;
`;

export const SummaryTextLightSC = styled.p`
  font-family: ${({ theme }) => theme.fonts.h400};
  font-size: 12px;
  line-height: 16px;
  color: #333333;
  margin-bottom: 8px;
  text-align: ${({ align }) => (align === "right" ? "right" : "left")};
`;

export const SummaryDateSC = styled.p`
  font-family: ${({ theme }) => theme.fonts.h700};
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.palette.primary900};
`;

export const SummaryConsumptionSC = styled.p`
  font-family: ${({ theme }) => theme.fonts.h700};
  color: ${({ theme }) => theme.palette.primary900};

  .consumption {
    font-size: 18px;
    line-height: 20px;
  }

  span {
    font-size: 12px;
    line-height: 16px;

    sup {
      font-size: 7px;
    }
  }
`;

export const SummaryTotalSC = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-family: ${({ theme }) => theme.fonts.h700};
  font-size: 10px;
  line-height: 16px;

  p.hrk {
    .hrk-num {
      color: ${({ theme }) => theme.palette.primary500};
      font-size: 18px;
      line-height: 20px;
      margin-right: 4px;
    }

    .unpaid {
      color: ${({ theme }) => theme.palette.danger500};
    }

    span {
      font-size: 12px;
      line-height: 20px;
      color: ${({ theme }) => theme.palette.primary900};
    }
  }

  p.eur {
    margin-bottom: 0;
    margin-right: -4px;
    .eur-num {
      color: #8b8a8d;
      font-size: 12px;
      line-height: 20px;
      margin-right: 4px;
    }

    span {
      font-size: 10px;
      line-height: 20px;
      color: #8b8a8d;
    }

    span.bracket {
      font-size: 12px;
      line-height: 20px;
      color: #8b8a8d;
      margin: 0 4px;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }
`;

export const ActionSectionSC = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 32px;
`;

export const IconDownloadSC = styled(Icon)`
  &.MuiIcon-root {
    width: 14px;
    height: 17px;
    -webkit-mask: url(${DownloadIconSvg}) no-repeat center;
    mask: url(${DownloadIconSvg}) no-repeat center;
    background-color: ${({ theme }) => theme.palette.primary900};
  }
`;

export const IconPaymentSC = styled(Icon)`
  &.MuiIcon-root {
    width: 20px;
    height: 16px;
    -webkit-mask: url(${PaymentIconSvg}) no-repeat center;
    mask: url(${PaymentIconSvg}) no-repeat center;
    background-color: ${({ theme }) => theme.palette.neutral100};
  }
`;

export const DownloadBillButtonSC = styled(Button)`
  &.MuiButton-root {
    background-color: transparent;
    border-radius: 53px;
    height: 40px;
    width: 146px;
    color: ${({ theme }) => theme.palette.primary900};
    font-family: ${({ theme }) => theme.fonts.b700};
    font-size: 12px;
    letter-spacing: 0.12px;
    text-transform: none;
    margin-right: 22px;

    &:hover {
      background-color: transparent;
    }
  }
`;

//
//
// ---------------------------------------------------------------
// ---------------------- Bill Payment ---------------------------
// ---------------------------------------------------------------

export const PaymentWrapperSC = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
`;

export const PaymentInnerWrapperSC = styled(Box)`
  margin-top: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const PaymentTitle = styled.h2`
  font-size: 24px;
  line-height: 48px;
  font-family: ${({ theme }) => theme.fonts.b400};
  font-weight: 400;
  color: ${({ theme }) => theme.palette.primary800};
  text-align: center;
  margin-bottom: 40px;
`;

export const PaymentText = styled.p`
  font-family: ${({ theme }) => theme.fonts.b400};
  font-size: 14px;
  line-height: 24px;
  color: ${({ theme }) => theme.palette.primary800};
`;

export const IconPaySuccessSC = styled(Icon)`
  margin-bottom: 40px;
  &.MuiIcon-root {
    width: 258px;
    height: 158px;
    background: url(${PaySuccessSvg}) no-repeat center;
    background-color: transparent;
  }
`;
