import styled from "styled-components";

import { Box, Button, Icon, Paper } from "@mui/material";

import CalendarIconSvg from "../../assets/img/calendar_icon.svg";
import InfoCircleIconSvg from "../../assets/img/info_circle_icon.svg";
import LeaderboardIconSvg from "../../assets/img/leaderboard_icon.svg";
import PendingActionsIconSvg from "../../assets/img/pending_actions_icon.svg";
import HomeIconSvg from "../../assets/img/home_icon.svg";
import CheckIconSvg from "../../assets/img/check_icon.svg";
import CancelIconSvg from "../../assets/img/cancel_icon.svg";
import ScheduleIconSvg from "../../assets/img/schedule_icon.svg";
import ClockIconSvg from "../../assets/img/clock_icon.svg";
import RocketIconSvg from "../../assets/img/rocket_icon.svg";
import SuccessMRImageSvg from "../../assets/img/success_measurement_report.svg";
import ArrowBackSvg from "../../assets/img/arrow_back_icon.svg";

// Dashboard
// Device Card
// Chart
// Device Details
// Year Details

//
//
// ---------------------------------------------------------------
// ---------------------- Dashboard ------------------------------
// ---------------------------------------------------------------

export const PageWrapperSC = styled(Box)`
  display: flex;
  width: 100%;
  height: 100%;
`;

export const LeftPageSideSC = styled(Box)`
  width: 392px;
  height: calc(100% - 120px);

  @media (max-width: 1024px) {
    height: calc(100% - 186px);
    margin-top: 64px;
  }

  @media (max-width: 500px) {
    height: calc(100% - 120px);
    margin-top: 0px;
    width: 100%;
  }
`;

export const TitleSC = styled.h1`
  font-family: ${({ theme }) => theme.fonts.h600};
  color: ${({ theme }) => theme.palette.primary800};
  font-size: 32px;
  line-height: 40px;
  letter-spacing: 0.56px;
  margin: 40px 0;
  padding-left: 24px;
`;

export const DeviceListSC = styled.ul`
  margin: 0;
  padding-left: 24px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #e5e4e8;
    border: none;
  }
  // Firefox
  scrollbar-color: #e5e4e8 transparent;
`;

export const RightPageSideSC = styled(Box)`
  flex: 1;
  overflow: auto;
  padding: 0 24px;
`;

export const RightPageSideContainerSC = styled(Box)`
  max-width: 1200px;
  margin: auto;
  position: relative;
`;

export const ButtonBillsBackSC = styled.button`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: none;
  outline: none;
  font-family: ${({ theme }) => theme.fonts.b400};
  color: ${({ theme }) => theme.palette.primary900};
  font-size: 18px;
  line-height: 24px;
  padding: 0;
  margin: 48px 0 8px;
  cursor: pointer;
`;

export const ArrowBackSC = styled(Icon)`
  margin-right: 12px;
  &.MuiIcon-root {
    width: 16px;
    height: 16px;
    -webkit-mask: url(${ArrowBackSvg}) no-repeat center;
    mask: url(${ArrowBackSvg}) no-repeat center;
    background-color: ${({ theme }) => theme.palette.primary900};
  }
`;

//
//
// ---------------------------------------------------------------
// ---------------------- Device Card ----------------------------
// ---------------------------------------------------------------

export const DeviceCardSC = styled(Paper)`
  margin-bottom: 20px;

  &.MuiPaper-root {
    cursor: pointer;
    padding: 20px 32px;
    background-color: ${({ theme }) => theme.palette.neutral0};
    border: 1px solid #eeeff9;
    border-radius: 8px;
    box-shadow: 0px 8px 24px -8px rgba(34, 3, 101, 0.1);
    width: 344px;

    @media (max-width: 500px) {
      width: calc(100vw - 48px);
    }
  }

  &.active {
    &.MuiPaper-root {
      user-select: text;
      -webkit-user-drag: none;
      background-color: ${({ theme }) => theme.palette.primary50};
    }

    header h3 {
      color: ${({ theme }) => theme.palette.primary500};
    }
  }

  header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;

    h3 {
      color: ${({ theme }) => theme.palette.primary800};
    }
  }

  div {
    margin-bottom: 24px;
  }

  footer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h3 {
      color: ${({ theme }) => theme.palette.primary500};
    }
  }

  h3 {
    font-family: ${({ theme }) => theme.fonts.b700};
    color: ${({ theme }) => theme.palette.primary900};
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.32px;
  }

  h4 {
    font-family: ${({ theme }) => theme.fonts.b700};
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 0.12px;
  }

  p {
    font-family: ${({ theme }) => theme.fonts.b400};
    color: ${({ theme }) => theme.palette.primary900};
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.12px;
  }
`;

//
//
// ---------------------------------------------------------------
// ---------------------- Chart -----------------------------
// ---------------------------------------------------------------

export const TooltipWrapperSC = styled(Box)`
  min-width: 120px;
  height: 80px;
  background-color: ${({ theme }) => theme.palette.neutral0};
  border-radius: 4px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 12px;
  position: relative;
  box-shadow: 0px 0px 12px 2px rgba(100, 100, 100, 0.1);
  z-index: 10;

  .box-tip {
    width: 16px;
    height: 16px;
    position: absolute;
    left: 52px;
    bottom: -14px;
    overflow: hidden;
    clip-path: polygon(50% 50%, 0 0, 100% 0);
    background-color: ${({ theme }) => theme.palette.neutral0};
  }
`;

export const TooltipTitleSC = styled.h3`
  font-family: ${({ theme }) => theme.fonts.b700};
  color: #b8b7bb;
  font-size: 10px;
  line-height: 16px;
`;

export const TooltipAmountSC = styled(Box)`
  .amount {
    display: inline;
    font-family: ${({ theme }) => theme.fonts.h700};
    color: ${({ theme }) => theme.palette.primary500};
    font-size: 18px;
    line-height: 20px;
    margin-right: 4px;
  }

  span {
    font-family: ${({ theme }) => theme.fonts.h700};
    color: ${({ theme }) => theme.palette.primary800};
    font-size: 12px;
    line-height: 16px;

    sup {
      font-size: 7px;
    }
  }
`;

export const TooltipDateSC = styled.h4`
  font-family: ${({ theme }) => theme.fonts.h500};
  font-weight: 500;
  font-size: 10px;
  color: ${({ theme }) => theme.palette.primary800};
  line-height: 16px;
`;

//
//
// ---------------------------------------------------------------
// ---------------------- DeviceDetails --------------------------
// ---------------------------------------------------------------

export const IconCalendarSC = styled(Icon)`
  &.MuiIcon-root {
    width: 18px;
    height: 20px;
    -webkit-mask: url(${CalendarIconSvg}) no-repeat center;
    mask: url(${CalendarIconSvg}) no-repeat center;

    &.clickable {
      background-color: ${({ theme }) => theme.palette.primary500};
      cursor: pointer;
    }
  }
`;

export const YearNavigationSC = styled(Box)`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  height: 32px;

  .MuiIcon-root {
    background-color: ${({ theme }) => theme.palette.primary800};
    margin: 0 3px;
  }
`;

export const YearMenuSC = styled(Box)`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.palette.neutral0};
  height: fit-content;
  margin-top: 12px;
  box-shadow: 0px 0px 12px 2px rgba(100, 100, 100, 0.1);

  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    background-color: ${({ theme }) => theme.palette.neutral0};
  }
  &::-webkit-scrollbar-thumb {
    background-color: #e5e4e8;
    border: none;
  }
  scrollbar-color: #e5e4e8 transparent;

  .box-tip {
    width: 16px;
    height: 16px;
    position: absolute;
    left: 33px;
    top: -4px;
    overflow: hidden;
    clip-path: polygon(50% 50%, 0% 100%, 100% 100%);
    background-color: ${({ theme }) => theme.palette.neutral0};
  }
`;

export const YearMenuItemSC = styled(Box)`
  background-color: transparent;
  color: ${({ theme }) => theme.palette.primary800};
  font-family: ${({ theme }) => theme.fonts.b700};
  font-size: 12px;
  line-height: 32px;
  width: 82px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    color: ${({ theme }) => theme.palette.neutral0};
    background-color: ${({ theme }) => theme.palette.primary500};
  }
`;

export const YearListSC = styled.ul`
  display: flex;
  margin-left: 32px;
  padding-left: 0;
`;

export const YearButtonSC = styled(Button)`
  &.MuiButton-root {
    min-width: 46px;
    width: 46px;
    height: 32px;
    margin-right: 8px;
    border-radius: 16px;
    background-color: transparent;
    color: ${({ theme }) => theme.palette.primary800};
    font-family: ${({ theme }) => theme.fonts.b700};
    font-size: 12px;
    &:hover {
      background-color: ${({ theme }) => theme.palette.primary500};
      color: ${({ theme }) => theme.palette.primary50};
    }
  }

  &.active {
    &.MuiButton-root {
      background-color: ${({ theme }) => theme.palette.primary500};
      color: ${({ theme }) => theme.palette.primary50};
    }
  }
`;

//
//
// ---------------------------------------------------------------
// ------------------------ YearDetails --------------------------
// ---------------------------------------------------------------
export const IconChartInfoCircleSC = styled(Icon)`
  &.MuiIcon-root {
    width: 20px;
    height: 20px;
    -webkit-mask: url(${InfoCircleIconSvg}) no-repeat center;
    mask: url(${InfoCircleIconSvg}) no-repeat center;
  }
`;

export const IconLeaderboardSC = styled(Icon)`
  &.MuiIcon-root {
    width: 20px;
    height: 18px;
    -webkit-mask: url(${LeaderboardIconSvg}) no-repeat center;
    mask: url(${LeaderboardIconSvg}) no-repeat center;
  }
`;

export const IconPendingActionsSC = styled(Icon)`
  &.MuiIcon-root {
    width: 18px;
    height: 21px;
    -webkit-mask: url(${PendingActionsIconSvg}) no-repeat center;
    mask: url(${PendingActionsIconSvg}) no-repeat center;
  }
`;

export const IconHomeSC = styled(Icon)`
  &.MuiIcon-root {
    width: 18px;
    height: 17px;
    -webkit-mask: url(${HomeIconSvg}) no-repeat center;
    mask: url(${HomeIconSvg}) no-repeat center;
  }
`;

export const IconCheckSC = styled(Icon)`
  &.MuiIcon-root {
    width: 20px;
    height: 20px;
    -webkit-mask: url(${CheckIconSvg}) no-repeat center;
    mask: url(${CheckIconSvg}) no-repeat center;
    background-color: ${({ theme }) => theme.palette.primary500};
  }
`;

export const IconScheduleSC = styled(Icon)`
  &.MuiIcon-root {
    width: 20px;
    height: 20px;
    -webkit-mask: url(${ScheduleIconSvg}) no-repeat center;
    mask: url(${ScheduleIconSvg}) no-repeat center;
    background-color: ${({ theme }) => "#eecc00"};
  }
`;

export const IconClockSC = styled(Icon)`
  &.MuiIcon-root {
    width: 20px;
    height: 20px;
    -webkit-mask: url(${ClockIconSvg}) no-repeat center;
    mask: url(${ClockIconSvg}) no-repeat center;
    background-color: ${({ theme }) => theme.palette.primary900};
  }
`;

export const IconCancelSC = styled(Icon)`
  &.MuiIcon-root {
    width: 20px;
    height: 20px;
    -webkit-mask: url(${CancelIconSvg}) no-repeat center;
    mask: url(${CancelIconSvg}) no-repeat center;
    background-color: ${({ theme }) => theme.palette.danger500};
  }
`;

export const IconRocketSC = styled(Icon)`
  &.MuiIcon-root {
    width: 19px;
    height: 19px;
    -webkit-mask: url(${RocketIconSvg}) no-repeat center;
    mask: url(${RocketIconSvg}) no-repeat center;
  }
`;

export const MeasurementReportNavSC = styled(Box)`
  height: 120px;
  display: flex;
  align-items: center;

  @media (max-width: 1024px) {
    justify-content: end;
  }
`;

export const GraphicSectionSC = styled(Box)`
  display: flex;
  width: 100%;
  margin-top: -50px;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

export const ChartSectionSC = styled(Box)`
  display: flex;
  flex-direction: column;
  margin-right: 24px;
  flex: 1;
  margin-top: 50px;

  @media (max-width: 600px) {
    margin-bottom: 24px;
    margin-right: 20px;
  }
`;

export const ChartInfoSC = styled(Box)`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  min-height: 32px;

  .MuiIcon-root {
    background-color: ${({ theme }) => theme.palette.primary800};
    margin: 0 2px;
  }
`;

export const ChartInfoTextSC = styled.p`
  color: ${({ theme }) => theme.palette.primary800};
  font-family: ${({ theme }) => theme.fonts.b400};
  font-size: 10px;
  line-height: 16px;
  margin-left: 32px;
`;

export const StatisticSectionSC = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 200px;
  height: auto;

  @media (max-width: 600px) {
    width: 100%;
  }

  & > div:first-child {
    display: flex;
    flex-direction: column;

    @media (max-width: 600px) {
      flex-direction: row;
      gap: 24px;
      flex: 1;
    }

    @media (max-width: 500px) {
      flex-direction: column;
      gap: 0;
    }
  }
`;

export const StatisticCardSC = styled(Paper)`
  &.MuiPaper-root {
    width: 200px;
    border-radius: 16px;
    padding: ${({ size }) =>
      size === "large" ? "17px 28px 20px 28px" : "19px 24px 16px 24px"};
    background-color: ${({ theme, bg }) =>
      bg === "dark" ? theme.palette.primary800 : theme.palette.primary500};
    color: ${({ theme }) => theme.palette.primary50};
    margin-bottom: 32px;

    @media (max-width: 1024px) {
      width: 100%;
    }

    @media (max-width: 600px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: ${({ last }) => !last && "24px"};
    }
  }

  .MuiIcon-root {
    background-color: ${({ theme }) => theme.palette.primary50};
  }

  p {
    font-family: ${({ theme }) => theme.fonts.b700};
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 0.12px;
    margin-bottom: ${({ size }) => (size === "large" ? "12px" : "4px")};
  }

  footer {
    display: flex;
    align-items: flex-end;

    .num-large {
      display: inline-block;
      font-family: ${({ theme }) => theme.fonts.h700};
      font-size: 28px;
      line-height: 36px;
      letter-spacing: 0.12px;
    }

    .num-small {
      display: inline-block;
      font-family: ${({ theme }) => theme.fonts.h700};
      font-size: 18px;
      line-height: 20px;
      letter-spacing: 0.12px;
    }

    span {
      font-size: 12px;
      line-height: 16px;
      margin-left: 4px;

      sup {
        font-size: 7px;
      }
    }
  }
`;

export const BillsSectionSC = styled(Box)`
  margin-top: 32px;
  padding-bottom: 24px;

  h2 {
    font-family: ${({ theme }) => theme.fonts.b400};
    font-weight: 400;
    font-size: 28px;
    line-height: 36px;
    color: ${({ theme }) => theme.palette.primary800};
    margin-bottom: 16px;
  }

  .MuiTableContainer-root {
    margin-bottom: 32px;
  }

  .MuiTableHead-root {
    .MuiTableCell-root {
      font-family: ${({ theme }) => theme.fonts.b700};
      font-size: 10px;
      line-height: 16px;
      color: ${({ theme }) => theme.palette.primary900};
      padding: 0;
      border-bottom: 0;
    }
  }

  .MuiTableBody-root {
    .MuiTableCell-root {
      height: 40px;
      font-family: ${({ theme }) => theme.fonts.b400};
      font-size: 12px;
      color: ${({ theme }) => theme.palette.primary800};
      padding: 8px 0;
      border-bottom: 1px solid #f4f3f6;

      a {
        font-family: ${({ theme }) => theme.fonts.b700};
        font-size: 12px;
        color: ${({ theme }) => theme.palette.primary500};
      }
    }
    .MuiIcon-root {
      vertical-align: middle;
    }
  }
`;

export const OnlineBillBoxSC = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 16px;
  border-bottom: 1px solid #e5e4e8;

  h3 {
    font-family: ${({ theme }) => theme.fonts.b700};
    display: inline-block;
    font-size: 12px;
    line-height: 24px;
    color: #313132;
    cursor: pointer;
  }

  p {
    font-family: ${({ theme }) => theme.fonts.b400};
    font-size: 14px;
    line-height: 24px;
    color: #313132;
  }
`;

// Measurement Report Modal

export const LastConsumptionWrapperSC = styled(Box)`
  width: 100%;
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TextLightSC = styled.p`
  font-family: ${({ theme }) => theme.fonts.b400};
  font-weight: 400;
  color: ${({ theme }) => theme.palette.primary900};
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.12px;
`;

export const TextBoldSC = styled.h3`
  font-family: ${({ theme }) => theme.fonts.b700};
  font-size: 12px;
  line-height: 24px;
  color: #313132;
`;

export const TextLastConsumptionSC = styled.p`
  margin-top: 8px;
  font-family: ${({ theme }) => theme.fonts.h700};
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.palette.primary900};
`;

export const SubsectionSC = styled(Box)`
  width: 100%;
  margin-bottom: 16px;
  display: flex;

  & > *:first-child {
    flex: 1;
    text-align: left;
    margin-right: 4px;
  }

  & > *:last-child {
    flex: 1;
    text-align: left;
    margin-left: 4px;
  }
`;

export const ButtonsWrapperSC = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: ${({ center }) => (center ? "center" : "space-between")};
  margin-top: 8px;
`;

export const IconSuccessMRSC = styled.img.attrs({
  src: SuccessMRImageSvg,
})`
  width: 108.6px;
  height: 96px;
  margin-bottom: 40px;
`;

export const TextSC = styled.p`
  font-family: ${({ theme }) => theme.fonts.b400};
  font-size: 14px;
  line-height: 24px;
  color: ${({ theme }) => theme.palette.primary800};
  margin-bottom: 40px;

  a {
    font-family: ${({ theme }) => theme.fonts.b700};
    color: ${({ theme }) => theme.palette.primary500};
  }
`;
