import { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { view, autoEffect } from "@risingstack/react-easy-state";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { loginSchema } from "./schemas";
import { auth } from "../../stores/auth";

import Button from "../../parts/Button/Button";

import { CircularProgress, Tooltip } from "@mui/material";

import {
  WrapperSC,
  ContentSC,
  TextSC,
  IconLoginSC,
  IconInfoSC,
  FormFooterSC,
  LoginTitleWrapperSC,
} from "./styled";
import { FormGroupsSC, TextErrorSC } from "../../parts/styled";

const Login = view(() => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(loginSchema) });
  const navigate = useNavigate();

  const [errorEmail, setErrorEmail] = useState("");
  const [errorPassword, setErrorPassword] = useState("");

  autoEffect(() => {
    if (auth.isLoggedIn) {
      navigate("/");
    }
  });

  autoEffect(() => {
    if (auth.errors?.message === "Password ili email ne postoji") {
      setErrorPassword("Kriva e-mail adresa ili lozinka");
    }
    if (
      auth.errors?.message === "Password ili email ne postoji"
    ) {
      setErrorEmail("Kriva e-mail adresa ili lozinka");
    }
    if (
      auth.errors?.errors?.email[0] === "The email must be a valid email address."
    ) {
      setErrorEmail("Upišite ispravnu e-mail adresu")
    }
  });

  useEffect(() => {
    return () => {
      auth.errors = null;
    };
  }, []);

  const onSubmit = async (data) => {
    await auth.login(data);
  };

  return (
    <WrapperSC>
      <ContentSC>
        <LoginTitleWrapperSC>
          <h1>Prijava</h1>
          <Tooltip title={"Informacije o aplikaciji"} placement="top" arrow>
            <IconInfoSC component={Link} to="/info" />
          </Tooltip>
        </LoginTitleWrapperSC>
        <form>
          <TextSC>
            Nemate korisnički račun?{" "}
            <Link to="/registracija">Registrirajte se</Link>!
          </TextSC>
          <FormGroupsSC flexrow>
            <div
              className={
                errorEmail || errors.email ? "form-group error" : "form-group"
              }
            >
              <div>
                <input
                  type="email"
                  id="email"
                  {...register("email")}
                  onInput={() => setErrorEmail("")}
                />
                <label htmlFor="email">Email adresa</label>
              </div>
              <TextErrorSC>{errorEmail || errors.email?.message}</TextErrorSC>
            </div>
            <div
              className={
                errorPassword || errors.password
                  ? "form-group error"
                  : "form-group"
              }
            >
              <div>
                <input
                  type="password"
                  id="password"
                  {...register("password")}
                  onInput={() => setErrorPassword("")}
                />
                <label htmlFor="password">Lozinka</label>
              </div>
              <TextErrorSC>
                {errorPassword || errors.password?.message}
              </TextErrorSC>
            </div>
          </FormGroupsSC>
          <TextSC>
            Zaboravljena lozinka?{" "}
            <Link to="/zaboravljena-lozinka">Promijenite lozinku</Link>.
          </TextSC>
          <FormFooterSC>
            <span></span>
            <Button
              onClick={handleSubmit(onSubmit)}
              colbg="primary500"
              coltext="primary50"
              endIcon={<IconLoginSC />}
              sx={{ marginBottom: "40px" }}
            >
              <span style={{ minWidth: "45px" }}>
                {auth.isLoading ? (
                  <CircularProgress
                    size={20}
                    sx={{ color: "white" }}
                    thickness={6}
                  />
                ) : (
                  "Prijava"
                )}
              </span>
            </Button>
          </FormFooterSC>
        </form>
      </ContentSC>
    </WrapperSC>
  );
});

export default Login;
