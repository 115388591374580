import styled from "styled-components";

import { Box, Icon } from "@mui/material";

import LogoFullSvg from "../../assets/img/logo_full_2.svg";
import InfoIconSvg from "../../assets/img/info_icon.svg";
import InfoCircleIconSvg from "../../assets/img/info_circle_icon.svg";
import ArrowBackIconSvg from "../../assets/img/arrow_back_icon.svg";

export const WrapperSC = styled(Box)`
  display: flex;
  height: 100%;

  @media (max-width: 1024px) {
    overflow-y: hidden;
  }
`;

export const LeftPageSideSC = styled(Box)`
  background-color: ${({ theme }) => theme.palette.primary900};
  width: 392px;
  min-height: 100%;
  height: calc(100vh - 40px);
  overflow-y: auto;
  padding: 0 24px;

  @media (max-width: 1024px) {
    height: calc(100% - 40px);
  }

  @media (max-width: 800px) {
    width: 100%;
  }
`;

export const TitlePrimarySC = styled.h1`
  font-family: ${({ theme }) => theme.fonts.b400};
  font-weight: 400;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: 0.56px;
  color: ${({ theme }) => theme.palette.primary50};
  margin: 40px 0;
`;

export const LogoFullSC = styled(Icon)`
  margin: 3.5px 0 27.5px;
  &.MuiIcon-root {
    width: 160px;
    height: 90px;
    background: url(${LogoFullSvg}) no-repeat center;
    background-color: transparent;
  }
`;

export const SubsectionSC = styled(Box)`
  margin-bottom: 40px;
`;

export const SubtitleSC = styled.h3`
  font-family: ${({ theme }) => theme.fonts.b700};
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.12px;
  color: ${({ theme }) => theme.palette.primary200};
`;

export const ParagraphSC = styled.p`
  font-family: ${({ theme }) => theme.fonts.b400};
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.12px;
  color: ${({ theme, color }) =>
    color === "black" ? theme.palette.primary800 : theme.palette.primary50};

  a {
    color: ${({ theme }) => theme.palette.primary500};
  }

  strong {
    font-family: ${({ theme }) => theme.fonts.b700};
  }
`;

export const RightPageSideSC = styled(Box)`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 24px;
  padding-right: 48px;

  div {
    max-width: 400px;
  }

  @media (max-width: 800px) {
    display: none;
  }
`;

export const IconInfoLargeSC = styled(Icon)`
  position: absolute;
  top: 52px;
  right: 48px;

  &.MuiIcon-root {
    width: 102px;
    height: 120px;
    -webkit-mask: url(${InfoIconSvg}) no-repeat center;
    -webkit-mask-size: contain;
    mask: url(${InfoIconSvg}) no-repeat center;
    mask-size: contain;
    background-color: ${({ theme }) => theme.palette.primary100};

    @media (max-width: 1024px) {
      display: none;
    }
  }
`;

export const RightContentSC = styled(Box)`
  height: fit-content;
`;

export const TitleSecondarySC = styled.h2`
  font-family: ${({ theme }) => theme.fonts.b400};
  font-weight: 400;
  font-size: 32px;
  line-height: 56px;
  letter-spacing: 0.56px;
  color: ${({ theme }) => theme.palette.primary800};
  margin-bottom: 40px;
`;

export const InfoContentSC = styled(Box)`
  display: flex;
`;

export const IconInfoCircleSC = styled(Icon)`
  margin-top: 2px;
  margin-left: 2px;
  &.MuiIcon-root {
    width: 20px;
    height: 20px;
    -webkit-mask: url(${InfoCircleIconSvg}) no-repeat center;
    mask: url(${InfoCircleIconSvg}) no-repeat center;
    background-color: ${({ theme }) => theme.palette.primary500};
  }
`;

export const ButtonWrapperSC = styled(Box)`
  margin-top: 40px;
  display: flex;
  justify-content: end;
`;

export const ButtonSmallScreenWrapperSC = styled(Box)`
  margin: 40px 0;
  display: flex;
  justify-content: center;
`;

export const IconArrowBackSC = styled(Icon)`
  &.MuiIcon-root {
    width: 16px;
    height: 16px;
    -webkit-mask: url(${ArrowBackIconSvg}) no-repeat center;
    mask: url(${ArrowBackIconSvg}) no-repeat center;
  }
`;

export const InfoContentInnerSC = styled(Box)`
  padding-left: 34px;

  ul {
    margin-top: 0;
    margin-bottom: 24px;
    padding-left: 24px;

    li {
      list-style-type: disc;
      color: ${({ theme }) => theme.palette.primary800};
    }
  }
`;

export const TextLoginSC = styled.p`
  font-family: ${({ theme }) => theme.fonts.b400};
  font-size: 14px;
  line-height: 24px;
  color: ${({ theme }) => theme.palette.primary800};
  margin-top: 32px;
  text-align: right;

  a {
    font-family: ${({ theme }) => theme.fonts.b700};
    color: ${({ theme }) => theme.palette.primary500};
  }
`;
