import { createGlobalStyle } from "styled-components";
import { ThemeProvider as SCThemeProvider } from "styled-components";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Todo: remove unnecesary fonts
// Montserrat fonts
import MontserratThin from "../assets/fonts/Montserrat/Montserrat-Thin.ttf"; // 100
import MontserratExtraLight from "../assets/fonts/Montserrat/Montserrat-ExtraLight.ttf"; // 200
import MontserratLight from "../assets/fonts/Montserrat/Montserrat-Light.ttf"; //300
import MontserratRegular from "../assets/fonts/Montserrat/Montserrat-Regular.ttf"; // 400
import MontserratMedium from "../assets/fonts/Montserrat/Montserrat-Medium.ttf"; // 500
import MontserratSemiBold from "../assets/fonts/Montserrat/Montserrat-SemiBold.ttf"; // 600
import MontserratBold from "../assets/fonts/Montserrat/Montserrat-Bold.ttf"; // 700
import MontserratExtraBold from "../assets/fonts/Montserrat/Montserrat-ExtraBold.ttf"; // 800
import MontserratBlack from "../assets/fonts/Montserrat/Montserrat-Black.ttf"; // 900

// Poppins fonts
import PoppinsThin from "../assets/fonts/Poppins/Poppins-Thin.ttf"; // 100
import PoppinsExtraLight from "../assets/fonts/Poppins/Poppins-ExtraLight.ttf"; // 200
import PoppinsLight from "../assets/fonts/Poppins/Poppins-Light.ttf"; //300
import PoppinsRegular from "../assets/fonts/Poppins/Poppins-Regular.ttf"; // 400
import PoppinsMedium from "../assets/fonts/Poppins/Poppins-Medium.ttf"; // 500
import PoppinsSemiBold from "../assets/fonts/Poppins/Poppins-SemiBold.ttf"; // 600
import PoppinsBold from "../assets/fonts/Poppins/Poppins-Bold.ttf"; // 700
import PoppinsExtraBold from "../assets/fonts/Poppins/Poppins-ExtraBold.ttf"; // 800
import PoppinsBlack from "../assets/fonts/Poppins/Poppins-Black.ttf"; // 900

const theme = createTheme({
  palette: {
    // Primary Colors //
    primary50: "#E5F8FF",
    primary100: "#CCF1FF",
    primary200: "#99E3FF",
    primary400: "#33C8FF",
    primary500: "#00BAFF",
    primary600: "#0095CC",
    primary800: "#004A66",
    primary900: "#002533",
    // Neutral Colors
    neutral0: "#FFFFFF",
    neutral50: "#FBFBFC",
    neutral100: "#F4F3F6",
    // Danger Colors
    danger500: "#FF0100",
  },
  fonts: {
    // Heading Fonts
    h100: "MontserratThin",
    h200: "MontserratExtraLight",
    h300: "MontserratLight",
    h400: "MontserratRegular",
    h500: "MontserratMedium",
    h600: "MontserratSemiBold",
    h700: "MontserratBold",
    h800: "MontserratExtraBold",
    h900: "MontserratBlack",
    // Body Fonts
    b100: "PoppinsThin",
    b200: "PoppinsExtraLight",
    b300: "PoppinsLight",
    b400: "PoppinsRegular",
    b500: "PoppinsMedium",
    b600: "PoppinsSemiBold",
    b700: "PoppinsBold",
    b800: "PoppinsExtraBold",
    b900: "PoppinsBlack",
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiIconButton: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: "#002533",
          color: "#F4F3F6",
          maxWidth: "350px",
        },
        arrow: {
          color: "#002533",
        },
      },
    },
  },
});

const GlobalStyle = createGlobalStyle`
  // Montserrat Fonts
  @font-face {
    font-family: 'MontserratThin'; // 100
    src: local('MontserratThin'), local('MontserratThin'),
    url(${MontserratThin}) format('truetype')
  }
  @font-face {
    font-family: 'MontserratExtraLight'; // 200
    src: local('MontserratExtraLight'), local('MontserratExtraLight'),
    url(${MontserratExtraLight}) format('truetype')
  }
  @font-face {
    font-family: 'MontserratLight'; // 300
    src: local('MontserratLight'), local('MontserratLight'),
    url(${MontserratLight}) format('truetype')
  }
  @font-face {
    font-family: 'MontserratRegular'; // 400
    src: local('MontserratRegular'), local('MontserratRegular'),
    url(${MontserratRegular}) format('truetype')
  }
  @font-face {
    font-family: 'MontserratMedium'; // 500
    src: local('MontserratMedium'), local('MontserratMedium'),
    url(${MontserratMedium}) format('truetype')
  }
  @font-face {
    font-family: 'MontserratSemiBold'; // 600
    src: local('MontserratSemiBold'), local('MontserratSemiBold'),
    url(${MontserratSemiBold}) format('truetype')
  }
  @font-face {
    font-family: 'MontserratBold'; // 700
    src: local('MontserratBold'), local('MontserratBold'),
    url(${MontserratBold}) format('truetype')
  }
  @font-face {
    font-family: 'MontserratExtraBold'; // 800
    src: local('MontserratExtraBold'), local('MontserratExtraBold'),
    url(${MontserratExtraBold}) format('truetype')
  }
  @font-face {
    font-family: 'MontserratBlack'; // 900
    src: local('MontserratBlack'), local('MontserratBlack'),
    url(${MontserratBlack}) format('truetype')
  }

  // Poppins Fonts
  @font-face {
    font-family: 'PoppinsThin'; // 100
    src: local('PoppinsThin'), local('PoppinsThin'),
    url(${PoppinsThin}) format('truetype')
  }
  @font-face {
    font-family: 'PoppinsExtraLight'; // 200
    src: local('PoppinsExtraLight'), local('PoppinsExtraLight'),
    url(${PoppinsExtraLight}) format('truetype')
  }
  @font-face {
    font-family: 'PoppinsLight'; // 300
    src: local('PoppinsLight'), local('PoppinsLight'),
    url(${PoppinsLight}) format('truetype')
  }
  @font-face {
    font-family: 'PoppinsRegular'; // 400
    src: local('PoppinsRegular'), local('PoppinsRegular'),
    url(${PoppinsRegular}) format('truetype')
  }
  @font-face {
    font-family: 'PoppinsMedium'; // 500
    src: local('PoppinsMedium'), local('PoppinsMedium'),
    url(${PoppinsMedium}) format('truetype')
  }
  @font-face {
    font-family: 'PoppinsSemiBold'; // 600
    src: local('PoppinsSemiBold'), local('PoppinsSemiBold'),
    url(${PoppinsSemiBold}) format('truetype')
  }
  @font-face {
    font-family: 'PoppinsBold'; // 700
    src: local('PoppinsBold'), local('PoppinsBold'),
    url(${PoppinsBold}) format('truetype')
  }
  @font-face {
    font-family: 'PoppinsExtraBold'; // 800
    src: local('PoppinsExtraBold'), local('PoppinsExtraBold'),
    url(${PoppinsExtraBold}) format('truetype')
  }
  @font-face {
    font-family: 'PoppinsBlack'; // 900
    src: local('PoppinsBlack'), local('PoppinsBlack'),
    url(${PoppinsBlack}) format('truetype')
  }

  ul, li {
    list-style: none;
  }

  a {
    text-decoration: none;
  }

  h1, h2, h3, h4, h5, h6, p {
    margin: 0;
  }
`;

const StyleProvider = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <SCThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyle />
        {children}
      </SCThemeProvider>
    </ThemeProvider>
  );
};

export default StyleProvider;
