import { useState, useEffect } from "react";
import { view } from "@risingstack/react-easy-state";
import { useLocation } from "react-router-dom";

import {
  FooterSC,
  IconPrivacySC,
  TextPrivacySC,
  FooterLinksSC,
  NavbarLink,
} from "./styled";

const Footer = view(() => {
  const [firstRender, setFirstRender] = useState(true);
  const location = useLocation();

  useEffect(() => {
    setFirstRender(false);
  }, []);
  return (
    <>
      {firstRender ||
      location.pathname.startsWith("/uspjesno-placanje") ||
      location.pathname.startsWith("/ponisteno-placanje") ? null : (
        <FooterSC>
          <TextPrivacySC>
            <span>&copy;</span>
            <p>2023. Miholjački Vodovod d.o.o. Sva prava pridržana.</p>
          </TextPrivacySC>
          <FooterLinksSC>
            <div>
              <IconPrivacySC />
            </div>
            <NavbarLink to="/politika-privatnosti">
              <p>Politika privatnosti</p>
            </NavbarLink>
            {/* <p>Cookie policy</p> */}
          </FooterLinksSC>
        </FooterSC>
      )}
    </>
  );
});

export default Footer;
