import { useState } from "react";
import { view } from "@risingstack/react-easy-state";
import { useNavigate } from "react-router-dom";

import useMediaQuery from "@mui/material/useMediaQuery";

import { auth } from "../../stores/auth";

import Button from "../../parts/Button/Button";
import Modal from "../../parts/Modal/Modal";

import { CircularProgress } from "@mui/material";

import Settings from "./Settings";

import {
  WrapperSC,
  LeftPageSideSC,
  TitlePrimarySC,
  AvatarLargeSC,
  SubsectionSC,
  SubtitleSC,
  ParagraphSC,
  VersionAndLogoutSC,
  VersionTextSC,
  RightPageSideSC,
  IconLogoutSC,
  IconSettingsLargeSC,
  TitleSecondarySC,
  ModalContentSC,
  TextSC,
  IconMailSendSC,
} from "./styled";

const Profile = view(() => {
  const navigate = useNavigate();
  const [modalRequestOpen, setModalRequestOpen] = useState(false);
  const [modalSuccessOpen, setModalSuccessOpen] = useState(false);
  const matches800Up = useMediaQuery("(min-width:800px)");

  const handleLogout = async () => {
    await auth.logout();
    navigate("/prijava");
  };

  const getUserInitials = () => {
    let firstLetter = "";
    let secondLetter = "";
    if (auth.userDetails?.name?.split(" ").length > 1) {
      firstLetter = auth.userDetails?.name?.split(" ")[0][0];
      secondLetter = auth.userDetails?.name?.split(" ")[1][0];
    } else {
      firstLetter = auth.userDetails?.name?.[0] || "";
    }
    let result = firstLetter?.toUpperCase() + secondLetter?.toUpperCase();
    if (!result) {
      return "K";
    }
    return result;
  };

  const handleRemoveAppDetails = () => {
    const mailReceiver = "info@miholjacki-vodovod.hr";
    const subject = "Zahtjev za brisanje osobnih podataka";
    const body =
      "Molim vas obrazac Zahtjeva za brisanje osobnih podataka koji su obrađeni u sustavu aplikacije Miholjačkog vodovoda.";
    window.location = `mailto:${mailReceiver}?subject=${subject}&body=${body}`;
    setModalRequestOpen(false);
    setModalSuccessOpen(true);
  };

  return (
    <WrapperSC>
      <LeftPageSideSC>
        <TitlePrimarySC>{auth.userDetails?.name}</TitlePrimarySC>
        <AvatarLargeSC>{getUserInitials()}</AvatarLargeSC>
        <SubsectionSC>
          <SubtitleSC>Elektronička pošta</SubtitleSC>
          <ParagraphSC>
            <a href={`mailto:${auth.userDetails?.email}`}>
              {auth.userDetails?.email}
            </a>
          </ParagraphSC>
        </SubsectionSC>
        <SubsectionSC>
          <SubtitleSC>OIB</SubtitleSC>
          <ParagraphSC>{auth.userDetails?.oib}</ParagraphSC>
        </SubsectionSC>
        {auth.userDetails?.user_code && (
          <SubsectionSC>
            <SubtitleSC>Šifra korisnika</SubtitleSC>
            <ParagraphSC>{auth.userDetails?.user_code}</ParagraphSC>
          </SubsectionSC>
        )}
        {auth.userDetails?.company && (
          <SubsectionSC>
            <SubtitleSC>Tvrtka</SubtitleSC>
            <ParagraphSC>{auth.userDetails?.company}</ParagraphSC>
          </SubsectionSC>
        )}
        {!matches800Up && (
          <Settings setModalRequestOpen={setModalRequestOpen} />
        )}
        <VersionAndLogoutSC>
          <div>
            <VersionTextSC>Verzija: 1.0.0.</VersionTextSC>
          </div>
          <div>
            <Button
              colbg="primary500"
              coltext="neutral100"
              onClick={handleLogout}
              endIcon={<IconLogoutSC />}
            >
              <span style={{ minWidth: "48px" }}>
                {auth.isLoading ? (
                  <CircularProgress
                    size={20}
                    sx={{ color: "white" }}
                    thickness={6}
                  />
                ) : (
                  "Odjava"
                )}
              </span>
            </Button>
          </div>
        </VersionAndLogoutSC>
      </LeftPageSideSC>
      {matches800Up && (
        <RightPageSideSC>
          <IconSettingsLargeSC />
          <div>
            <TitleSecondarySC>Postavke korisničkog računa</TitleSecondarySC>
            <Settings setModalRequestOpen={setModalRequestOpen} />
          </div>
        </RightPageSideSC>
      )}
      <Modal
        open={modalRequestOpen}
        title="Pošalji Zahtjev za brisanje osobnih podataka?"
      >
        <TextSC>
          Žao nam je što brišete Vaš korisnički račun i želite se odreći mnogih
          pogodnosti koje aplikacija nudi. Ako ste još uvijek sigurni, možete
          zatražiti obrazac Zahtjeva za brisanje od naše korisničke službe.
        </TextSC>
        <ModalContentSC>
          <Button
            colbg="primary500"
            coltext="primary500"
            outlined="true"
            onClick={() => setModalRequestOpen(false)}
          >
            Odustani
          </Button>
          <Button
            colbg="primary500"
            coltext="primary50"
            onClick={handleRemoveAppDetails}
          >
            Potvrdi
          </Button>
        </ModalContentSC>
      </Modal>
      <Modal open={modalSuccessOpen} title="Zahtjev poslan">
        <IconMailSendSC />
        <ModalContentSC style={{ justifyContent: "center" }}>
          <Button
            colbg="primary500"
            coltext="primary50"
            onClick={() => setModalSuccessOpen(false)}
          >
            Povratak na aplikaciju
          </Button>
        </ModalContentSC>
      </Modal>
    </WrapperSC>
  );
});

export default Profile;
