import { view } from "@risingstack/react-easy-state";
import NumberFormat from "react-number-format";

const Number = view(({ ...rest }) => {
  return (
    <NumberFormat
      isNumericString={true}
      decimalSeparator=","
      thousandSeparator="."
      fixedDecimalScale
      displayType="text"
      {...rest}
    />
  );
});

export default Number;
