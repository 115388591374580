import { view } from "@risingstack/react-easy-state";
import { IconGeneralErrorSC, MessageWrapperSC } from "./styled";

const NotFound = view(({ message }) => {
  return (
    <MessageWrapperSC>
      <div style={{ marginTop: "120px" }}>
        <IconGeneralErrorSC />
        <p>{message}</p>
      </div>
    </MessageWrapperSC>
  );
});

export default NotFound;
