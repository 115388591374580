import { useState, useEffect } from "react";
import { useNavigate, Link, useSearchParams } from "react-router-dom";
import { view, autoEffect } from "@risingstack/react-easy-state";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { resetPasswordSchema } from "./schemas";
import { auth } from "../../stores/auth";

import Modal from "../../parts/Modal/Modal";
import Button from "../../parts/Button/Button";

import { CircularProgress } from "@mui/material";

import {
  WrapperSC,
  ContentSC,
  TextSC,
  LinkBackWrapperSC,
  FormFooterSC,
} from "./styled";
import { FormGroupsSC, TextErrorSC } from "../../parts/styled";

const ResetPassword = view(() => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const [modalSuccessOpen, setModalSuccessOpen] = useState(false);
  const [modalErrorOpen, setModalErrorOpen] = useState(false);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(resetPasswordSchema) });

  autoEffect(() => {
    if (!token && !auth.isLoggedIn) {
      navigate("/prijava");
    }
    if (auth.isLoggedIn) {
      navigate("/");
    }
  });

  useEffect(() => {
    return () => {
      auth.isResetError = false;
      auth.isResetSuccess = false;
    };
  }, []);

  const onSubmit = async ({password}) => {
    await auth.resetPassword(password, token);
    if (auth.isResetSuccess) {
      setModalSuccessOpen(true);
    }
    if (auth.isResetError) {
      setModalErrorOpen(true);
    }
  };

  const handleCloseModalSuccess = () => {
    auth.isRegisterSuccess = false;
    setModalSuccessOpen(false);
    navigate("/prijava", { replace: true });
  };

  const handleCloseModalError = () => {
    auth.errors = false;
    setModalErrorOpen(false);
    navigate("/prijava", { replace: true });
  };

  return (
    <WrapperSC>
      <ContentSC>
        <h1>Promjena lozinke</h1>
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextSC>Unesite novu lozinku.</TextSC>
          <FormGroupsSC flexrow>
            <div
              className={errors.password ? "form-group error" : "form-group"}
            >
              <div>
                <input
                  type="password"
                  id="password"
                  {...register("password")}
                  autoComplete="new-password"
                />
                <label htmlFor="password">Lozinka</label>
              </div>
              <TextErrorSC>{errors.password?.message}</TextErrorSC>
            </div>
            <div
              className={
                errors.confirmPassword ? "form-group error" : "form-group"
              }
            >
              <div>
                <input
                  type="password"
                  id="confirm-password"
                  {...register("confirmPassword")}
                  autoComplete="new-password"
                />
                <label htmlFor="confirm-password">Potvrda lozinke</label>
              </div>
              <TextErrorSC>{errors.confirmPassword?.message}</TextErrorSC>
            </div>
          </FormGroupsSC>
          <FormFooterSC>
            <LinkBackWrapperSC style={{ marginBottom: "40px" }}>
              <Link to="/prijava">Povratak na prijavu</Link>
            </LinkBackWrapperSC>
            <Button
              onClick={handleSubmit(onSubmit)}
              colbg="primary500"
              coltext="primary50"
              sx={{ marginBottom: "40px" }}
            >
              <span style={{ minWidth: "45px" }}>
                {auth.isLoading ? (
                  <CircularProgress
                    size={20}
                    sx={{ color: "white" }}
                    thickness={6}
                  />
                ) : (
                  "Potvrdi"
                )}
              </span>
            </Button>
          </FormFooterSC>
        </form>
      </ContentSC>
      <Modal open={modalSuccessOpen} title="Lozinka uspješno promijenjena!">
        <Button
          colbg="primary500"
          coltext="primary50"
          onClick={handleCloseModalSuccess}
        >
          Povratak na prijavu
        </Button>
      </Modal>
      <Modal open={modalErrorOpen} title="Došlo je do pogreške!">
        <Button
          colbg="primary500"
          coltext="primary50"
          onClick={handleCloseModalError}
        >
          Povratak na prijavu
        </Button>
      </Modal>
    </WrapperSC>
  );
});

export default ResetPassword;
