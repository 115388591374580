import { store } from "@risingstack/react-easy-state";
import { API_ENDPOINT } from "../config/constants.js";
import { authHeader } from "./auth";

export const payment = store({
  billIdLoading: null,
  isLoadingPayAll: false,
  paymentUrl: null,
  isLoadingConfirm: false,
  isConfirmSuccessfull: false,
  isConfirmError: false,

  async pay(amount, idArray, url) {
    try {
      const response = await fetch(`${API_ENDPOINT}/system/make-payment`, {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          ...authHeader(),
        },
        body: JSON.stringify({ amount: amount, bill_id: idArray, url: url }),
      });

      if (!response.ok) {
        throw Error(response.statusText);
      }

      const responseJson = await response.json();

      payment.paymentUrl = responseJson.payment_url;
    } catch (error) {
      console.log(error);
    }
  },

  async confirmPayment(key, id) {
    payment.isLoadingConfirm = true;

    try {
      const response = await fetch(`${API_ENDPOINT}/system/confirm-payment?key=${key}&bill=${id}`, {
        method: "PATCH",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          ...authHeader(),
        },
      });

      if (!response.ok) {
        throw Error(response.statusText);
      }

      await response.json();

      payment.isConfirmSuccessfull = true;
      payment.isLoadingConfirm = false;
    } catch (error) {
      payment.isConfirmError = true;
      payment.isLoadingConfirm = false;
      console.log(error);
    }
  },
});
