import { store } from "@risingstack/react-easy-state";

import { API_ENDPOINT } from "../config/constants.js";

import { devices } from "./devices.js";
import { notifications } from "./notifications.js";

export function authHeader() {
  const token = JSON.parse(localStorage.getItem("vodovod-user"));
  if (token) {
    return { Authorization: "Bearer " + token };
  }
}

function clearStore() {
  auth.token = null;
  auth.userDetails = null;
  auth.isLoggedIn = false;
  auth.errors = null;
  devices.allDevices = null;
  devices.device = null;
  notifications.allNotifications = null;
  notifications.notification = null;
}

export const auth = store({
  token: null,
  userDetails: null,
  isLoggedIn: false,
  errors: null,
  isLoading: false,
  isLoadingUser: false,
  isRegisterSuccess: false,
  isForgotPasswordSuccess: false,
  isResetSuccess: false,
  isResetError: false,
  isLoadingUpdate: false,
  isUpdateSuccess: false,

  async register({ email, oib, user_code, password }) {
    auth.errors = null;
    auth.isLoading = true;
    try {
      const response = await fetch(`${API_ENDPOINT}/system/register`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email,
          password: password,
          user_code: user_code,
          oib: oib,
        }),
      });
      if (!response.ok) {
        const responseJson = await response.json();
        auth.errors = responseJson.errors;
        throw Error(response.statusText);
      }

      const responseJson = await response.json();
      const { token, ...userData } = responseJson;
      localStorage.setItem("vodovod-user", JSON.stringify(token));
      auth.userDetails = userData;
      auth.isRegisterSuccess = true;
      auth.isLoggedIn = true;
      auth.isLoading = false;
    } catch (error) {
      auth.token = null;
      auth.userDetails = null;
      auth.isLoading = false;
    }
  },

  async login({ email, password }) {
    auth.isLoading = true;
    try {
      const response = await fetch(`${API_ENDPOINT}/system/login`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email,
          password: password,
        }),
      });
      if (!response.ok) {
        const responseJson = await response.json();
        auth.errors = responseJson;
        throw Error(response.statusText);
      }
      const responseJson = await response.json();
      const { token, ...userData } = responseJson;
      localStorage.setItem("vodovod-user", JSON.stringify(token));
      auth.userDetails = userData;
      auth.errors = null;
      auth.isLoggedIn = true;
      auth.isLoading = false;
    } catch (error) {
      auth.token = null;
      auth.userDetails = null;
      auth.isLoading = false;
      console.log(error);
    }
  },

  async getUser() {
    auth.isLoadingUser = true;
    let token = JSON.parse(localStorage.getItem("vodovod-user"));
    if (token) {
      auth.token = token;
      await auth.getUserDetails();
    }
    auth.isLoadingUser = false;
  },

  async getUserDetails() {
    try {
      const response = await fetch(`${API_ENDPOINT}/system/user-details`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          ...authHeader(),
        },
      });
      if (!response.ok) {
        throw Error(response.statusText);
      }
      const responseJson = await response.json();
      auth.userDetails = responseJson;
      auth.isLoggedIn = true;
    } catch (error) {
      console.log(error);
    }
  },

  async updateUser(mailNotifiable) {
    auth.isLoadingUpdate = true;
    try {
      const response = await fetch(`${API_ENDPOINT}/system/user-details`, {
        method: "PATCH",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          ...authHeader(),
        },
        body: JSON.stringify({
          mail_notifiable: mailNotifiable,
        }),
      });
      if (!response.ok) {
        throw Error(response.statusText);
      }
      await response.json();
      auth.isUpdateSuccess = true;
      auth.isLoadingUpdate = false;
    } catch (error) {
      auth.isLoadingUpdate = false;
      console.log(error);
    }
  },

  async logout() {
    auth.isLoading = true;
    try {
      const response = await fetch(`${API_ENDPOINT}/system/logout`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          ...authHeader(),
        },
      });
      if (!response.ok) {
        throw Error(response.statusText);
      }
      localStorage.removeItem("vodovod-user");
      clearStore();
      auth.isLoading = false;
    } catch (error) {
      auth.isLoading = false;
      console.log(error);
    }
  },

  async forgotPassword({ email }) {
    auth.isLoading = true;
    try {
      const response = await fetch(`${API_ENDPOINT}/system/forgot-password`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email,
        }),
      });
      if (!response.ok) {
        const responseJson = await response.json();
        auth.errors = responseJson;
        throw Error(response.statusText);
      }
      auth.isForgotPasswordSuccess = true;
      auth.isLoading = false;
    } catch (error) {
      console.log(error);
      auth.isLoading = false;
    }
  },

  async resetPassword(password, token) {
    auth.isLoading = true;
    try {
      const response = await fetch(`${API_ENDPOINT}/system/reset-password`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          password: password,
          token: token,
        }),
      });
      if (!response.ok) {
        throw Error(response.statusText);
      }
      auth.isResetSuccess = true;
      auth.isLoading = false;
    } catch (error) {
      console.log(error);
      auth.isResetError = true;
      auth.isLoading = false;
    }
  },
});
