import styled from "styled-components";

import { Box, Paper, Icon } from "@mui/material";

import NotificationIconSvg from "../../assets/img/notification_icon.svg";
import NotificationActiveIconSvg from "../../assets/img/notification_active_icon.svg";
import ArrowBackSvg from "../../assets/img/arrow_back_icon.svg";

// Notifications
// Notification Details

//
//
// ---------------------------------------------------------------
// --------------------------- Notifications -----------------------------
// ---------------------------------------------------------------
export const PageWrapperSC = styled(Box)`
  display: flex;
  width: 100%;
  height: 100%;
`;

export const LeftPageSideSC = styled(Box)`
  width: 392px;
  height: calc(100% - 160px);

  @media (max-width: 1024px) {
    height: calc(100% - 224px);
    margin-top: 64px;
  }

  @media (max-width: 500px) {
    height: calc(100% - 160px);
    margin-top: 0px;
    width: 100%;
  }
`;

export const TitleSC = styled.h1`
  font-family: ${({ theme }) => theme.fonts.h600};
  color: ${({ theme }) => theme.palette.primary800};
  font-size: 32px;
  line-height: 40px;
  letter-spacing: 0.56px;
  margin: 40px 0;
  padding-left: 24px;
`;

export const NotificationListSettingsSC = styled(Box)`
  top: 120px;
  width: 100%;
  height: 40px;
  padding: 0 24px;
  display: flex;
  justify-content: end;
  align-items: end;
`;

export const SettingsButtonSC = styled.button`
  padding: 0;
  margin-bottom: 20px;
  height: fit-content;
  outline: none;
  border: none;
  background-color: transparent;
  font-size: 12px;
  font-family: ${({ theme }) => theme.fonts.h700};
  color: ${({ theme }) => theme.palette.primary800};
  cursor: pointer;
`;

export const NotificationListSC = styled(Box)`
  margin: 0;
  padding-left: 24px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #e5e4e8;
    border: none;
  }

  // Firefox
  scrollbar-color: #e5e4e8 transparent;
`;

export const RightPageSideSC = styled(Box)`
  flex: 1;
  overflow: auto;
  padding: 0 24px;
`;

export const RightPageSideContainerSC = styled(Box)`
  max-width: 1200px;
  margin: auto;
`;

export const ButtonBillsBackSC = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: none;
  outline: none;
  font-family: ${({ theme }) => theme.fonts.b400};
  color: ${({ theme }) => theme.palette.primary900};
  font-size: 18px;
  line-height: 24px;
  padding: 0;
  margin: 48px 0 8px;
  cursor: pointer;
`;

export const ArrowBackSC = styled(Icon)`
  margin-right: 12px;
  &.MuiIcon-root {
    width: 16px;
    height: 16px;
    -webkit-mask: url(${ArrowBackSvg}) no-repeat center;
    mask: url(${ArrowBackSvg}) no-repeat center;
    background-color: ${({ theme }) => theme.palette.primary900};
  }
`;

// Notification Card
export const NotificationCardSC = styled(Paper)`
  margin-bottom: 8px;
  &.MuiPaper-root {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 8px 12px;
    background-color: ${({ theme }) => theme.palette.neutral0};
    border: 1px solid #eeeff9;
    border-radius: 8px;
    box-shadow: 0px 4px 12px -4px rgba(34, 3, 101, 0.1);
    width: 344px;

    &.unseen {
      background-color: ${({ theme }) => theme.palette.primary100};
    }

    @media (max-width: 500px) {
      width: calc(100% - 20px);
    }
  }

  &.active {
    &.MuiPaper-root {
      user-select: text;
      -webkit-user-drag: none;
      background-color: ${({ theme }) => theme.palette.primary50};
    }
    h3 {
      color: ${({ theme }) => theme.palette.primary500};
    }
  }
`;

export const NotificationCardContentSC = styled(Box)`
  margin-left: 8px;
  h3 {
    font-size: 12px;
    line-height: 16px;
    font-family: ${({ theme }) => theme.fonts.h700};
    color: ${({ theme }) => theme.palette.primary800};
  }

  p {
    font-size: 10px;
    line-height: 16px;
    font-family: ${({ theme }) => theme.fonts.h400};
    color: ${({ theme }) => theme.palette.primary800};
  }
`;

export const IconWrapperSC = styled(Box)`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.primary50};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const IconNotificationSC = styled(Icon)`
  &.MuiIcon-root {
    width: 15.2px;
    height: 19.5px;
    -webkit-mask: url(${NotificationIconSvg}) no-repeat center;
    mask: url(${NotificationIconSvg}) no-repeat center;
    background-color: ${({ theme }) => theme.palette.primary500};
  }
`;

export const IconNotificationActiveSC = styled(Icon)`
  &.MuiIcon-root {
    width: 19.75px;
    height: 19.5px;
    -webkit-mask: url(${NotificationActiveIconSvg}) no-repeat center;
    mask: url(${NotificationActiveIconSvg}) no-repeat center;
    background-color: ${({ theme }) => theme.palette.primary500};
  }
`;

export const ModalContentSC = styled(Box)`
  display: flex;
  justify-content: space-between;
`;

//
//
// ---------------------------------------------------------------
// ---------------------- Notification Details ---------------------------
// ---------------------------------------------------------------

export const TitleNotificationSC = styled.h2`
  font-family: ${({ theme }) => theme.fonts.b400};
  font-weight: 400;
  font-size: 28px;
  line-height: 36px;
  letter-spacing: 0.56px;
  color: ${({ theme }) => theme.palette.primary900};
  margin-top: 120px;

  @media (max-width: 1024px) {
    margin-top: 40px;
  }
`;

export const DateNotificationSC = styled.p`
  font-family: ${({ theme }) => theme.fonts.b400};
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.palette.primary900};
  margin-bottom: 40px;
  margin-top: 8px;
`;

export const MessageNotificationSC = styled.p`
  font-family: ${({ theme }) => theme.fonts.b400};
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: ${({ theme }) => theme.palette.primary900};
  margin-bottom: 40px;
`;
