import { useEffect, useState } from "react";
import { view, autoEffect } from "@risingstack/react-easy-state";
import { useNavigate, Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { registerSchema } from "./schemas";
import { auth } from "../../stores/auth";

import Button from "../../parts/Button/Button";
import Modal from "../../parts/Modal/Modal";

import { CircularProgress, Tooltip } from "@mui/material";

import {
  WrapperSC,
  ContentSC,
  TextSC,
  IconLoginSC,
  IconInfoLabelSC,
  IconMailSendSC,
  LabelIconBoxSC,
  FormFooterSC,
} from "./styled";
import {
  FormGroupsSC,
  TextErrorSC,
  CheckboxWrapperSC,
  CheckboxSC,
} from "../../parts/styled";

const Registration = view(() => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(registerSchema) });
  const navigate = useNavigate();

  const [errorEmail, setErrorEmail] = useState("");
  const [errorOib, setErrorOib] = useState("");
  const [errorUserCode, setErrorUserCode] = useState("");
  const [modalOpen, setModalOpen] = useState(false);

  autoEffect(() => {
    if (auth.isLoggedIn && !auth.isRegisterSuccess) {
      navigate("/");
    }
  });

  autoEffect(() => {
    if (
      auth.errors?.email &&
      auth.errors.email[0] === "Email adresa je zauzeta"
    ) {
      setErrorEmail("Korisnički račun s navedenom e-mail adresom već postoji");
    }
    if (
      auth.errors?.oib &&
      auth.errors.oib[0] === "Korisnik s ovim OIB-om je ve\u0107 registriran"
    ) {
      setErrorOib("Korisnički račun s navedenom OIB-om već postoji");
    }
    if (
      auth.errors?.user_code &&
      auth.errors.user_code[0] === "The user code has already been taken."
    ) {
      setErrorUserCode("Korisnički račun s navedenom šifrom već postoji");
    }
  });

  useEffect(() => {
    return () => {
      auth.errors = null;
      auth.isRegisterSuccess = false;
    };
  }, []);

  const preventNonNumericalInputOib = (e) => {
    e = e || window.event;
    var charCode = typeof e.which == "undefined" ? e.keyCode : e.which;
    var charStr = String.fromCharCode(charCode);
    if (!charStr.match(/^[0-9]+$/) || e.target.value.length === 11) {
      e.preventDefault();
    }
  };

  const preventNonNumericalInputCode = (e) => {
    e = e || window.event;
    var charCode = typeof e.which == "undefined" ? e.keyCode : e.which;
    var charStr = String.fromCharCode(charCode);
    if (!charStr.match(/^[0-9]+$/) || e.target.value.length === 6) {
      e.preventDefault();
    }
  };

  const onSubmit = async (data) => {
    await auth.register(data);
    if (auth.isRegisterSuccess) {
      setModalOpen(true);
    }
  };

  const handleCloseModal = () => {
    auth.isRegisterSuccess = false;
    setModalOpen(false);
    navigate("/");
  };

  return (
    <WrapperSC className="registration">
      <ContentSC>
        <h1>Registracija</h1>
        <form>
          <TextSC>
            Imate korisnički račun? <Link to="/prijava">Prijavite se</Link>!
          </TextSC>
          <FormGroupsSC flexrow>
            <div
              className={
                errorOib || errors.oib ? "form-group error" : "form-group"
              }
            >
              <div>
                <input
                  type="text"
                  id="oib"
                  {...register("oib")}
                  onInput={() => setErrorOib("")}
                  onKeyPress={preventNonNumericalInputOib}
                />
                <label htmlFor="oib">OIB</label>
              </div>
              <TextErrorSC>{errorOib || errors.oib?.message}</TextErrorSC>
            </div>
            <div
              className={errors.user_code ? "form-group error" : "form-group"}
            >
              <div>
                <input
                  type="text"
                  id="user_code"
                  onKeyPress={preventNonNumericalInputCode}
                  {...register("user_code")}
                />
                <LabelIconBoxSC>
                  <label htmlFor="user_code">MbV</label>
                  <Tooltip
                    title={
                      <>
                        <span>Šesteroznamenkasta oznaka na računu MbV. </span>
                        <a
                          style={{
                            color: "white",
                            textDecoration: "underline",
                          }}
                          href="https://miholjacki-vodovod.hr/samoocitanje/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Više
                        </a>
                      </>
                    }
                    placement="top"
                    arrow
                  >
                    <IconInfoLabelSC />
                  </Tooltip>
                </LabelIconBoxSC>
              </div>
              <TextErrorSC>{errorUserCode}</TextErrorSC>
            </div>
          </FormGroupsSC>
          <FormGroupsSC>
            <div
              className={
                errorEmail || errors.email ? "form-group error" : "form-group"
              }
            >
              <div>
                <input
                  type="email"
                  id="email"
                  {...register("email")}
                  onInput={() => setErrorEmail("")}
                />
                <label htmlFor="email">Email</label>
              </div>
              <TextErrorSC>{errorEmail || errors.email?.message}</TextErrorSC>
            </div>
          </FormGroupsSC>
          <FormGroupsSC flexrow>
            <div
              className={errors.password ? "form-group error" : "form-group"}
            >
              <div>
                <input
                  type="password"
                  id="password"
                  {...register("password")}
                />
                <label htmlFor="password">Lozinka</label>
              </div>
              <TextErrorSC>{errors.password?.message}</TextErrorSC>
            </div>
            <div
              className={
                errors.confirmPassword ? "form-group error" : "form-group"
              }
            >
              <div>
                <input
                  type="password"
                  id="confirm-password"
                  {...register("confirmPassword")}
                />
                <label htmlFor="confirm-password">Potvrdite lozinku</label>
              </div>
              <TextErrorSC>{errors.confirmPassword?.message}</TextErrorSC>
            </div>
          </FormGroupsSC>
          <CheckboxWrapperSC>
            <div>
              <CheckboxSC id="terms" {...register("terms")} color="primary" />
              <label htmlFor="terms">
                Slažem se s uvjetima korištenja aplikacije.
              </label>
            </div>
            <TextErrorSC>{errors.terms?.message}</TextErrorSC>
          </CheckboxWrapperSC>
          <FormFooterSC>
            <span></span>
            <Button
              onClick={handleSubmit(onSubmit)}
              colbg="primary500"
              coltext="primary50"
              endIcon={<IconLoginSC />}
            >
              <span style={{ minWidth: "76px" }}>
                {auth.isLoading ? (
                  <CircularProgress
                    size={20}
                    sx={{ color: "white" }}
                    thickness={6}
                  />
                ) : (
                  "Registracija"
                )}
              </span>
            </Button>
          </FormFooterSC>
        </form>
      </ContentSC>
      <Modal open={modalOpen} title="Uspješna registracija!">
        <IconMailSendSC />
        <TextSC>Uspješno ste završili registraciju.</TextSC>
        <Button
          colbg="primary500"
          coltext="primary50"
          onClick={handleCloseModal}
        >
          Nastavi
        </Button>
      </Modal>
    </WrapperSC>
  );
});

export default Registration;
