import { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { view, autoEffect } from "@risingstack/react-easy-state";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { forgotPasswordSchema } from "./schemas";
import { auth } from "../../stores/auth";

import Button from "../../parts/Button/Button";
import Modal from "../../parts/Modal/Modal";

import { CircularProgress } from "@mui/material";

import {
  WrapperSC,
  ContentSC,
  TextSC,
  LinkBackWrapperSC,
  IconKeySC,
  IconMailSendSC,
  IconArrowBackSC,
  FormFooterSC,
} from "./styled";
import { FormGroupsSC, TextErrorSC } from "../../parts/styled";

const ForgotPassword = view(() => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(forgotPasswordSchema) });
  const navigate = useNavigate();

  const [authError, setAuthError] = useState("");
  const [modalOpen, setModalOpen] = useState(false);

  autoEffect(() => {
    if (auth.isLoggedIn) {
      navigate("/");
    }
  });

  autoEffect(() => {
    if (auth.errors?.message.startsWith("There is no user with email")) {
      setAuthError("Korisnički račun s navedenom e-mail adresom ne postoji");
    }
  });

  useEffect(() => {
    return () => {
      auth.isForgotPasswordSuccess = false;
    };
  }, []);

  const onSubmit = async (data) => {
    await auth.forgotPassword(data);
    if (auth.isForgotPasswordSuccess) {
      setModalOpen(true);
    }
  };

  return (
    <WrapperSC>
      <ContentSC>
        <h1>Promjena lozinke</h1>
        <form>
          <TextSC>
            Upišite svoju email adresu kako biste promijenili svoju lozinku.
          </TextSC>
          <FormGroupsSC>
            <div
              className={
                authError || errors.email ? "form-group error" : "form-group"
              }
            >
              <div>
                <input
                  type="email"
                  id="email"
                  {...register("email")}
                  onInput={() => setAuthError("")}
                />
                <label htmlFor="email">Email adresa</label>
              </div>
              <TextErrorSC>{authError || errors.email?.message}</TextErrorSC>
            </div>
          </FormGroupsSC>
          <FormFooterSC>
            <LinkBackWrapperSC>
              <Link to="/prijava">Povratak na prijavu</Link>
            </LinkBackWrapperSC>
            <Button
              onClick={handleSubmit(onSubmit)}
              colbg="primary500"
              coltext="primary50"
              endIcon={<IconKeySC />}
            >
              <span style={{ minWidth: "45px" }}>
                {auth.isLoading ? (
                  <CircularProgress
                    size={20}
                    sx={{ color: "white" }}
                    thickness={6}
                  />
                ) : (
                  "Pošalji"
                )}
              </span>
            </Button>
          </FormFooterSC>
        </form>
      </ContentSC>
      <Modal open={modalOpen} title="Uspješno poslano">
        <IconMailSendSC />
        <TextSC>
          Provjerite svoj inbox. Ako imate korisnički račun s ovom email
          adresom, pratite upute i link za reset lozinke.
        </TextSC>
        <Button
          colbg="primary500"
          coltext="primary50"
          component={Link}
          to="/prijava"
          endIcon={<IconArrowBackSC />}
        >
          Povratak na prijavu
        </Button>
      </Modal>
    </WrapperSC>
  );
});

export default ForgotPassword;
