import React from "react";
import {
  TitlePrimarySC,
  WrapperSC,
  SubtitleSC,
  NavbarLink,
  LinkBackWrapperSC,
} from "./styled";

const PrivacyPolicy = () => {
  return (
    <WrapperSC>
      <TitlePrimarySC> Politika zaštite privatnih podataka</TitlePrimarySC>
      <SubtitleSC>
        <b>MIHOLJAČKI VODOVOD D.O.O.</b> sa svim svojim voditeljima
        organizacijskih dijelova i zaposlenicima opredijeljeni su da provode
        obvezu zaštite osobnih podataka ispitanika temeljem Zakona o provedbi
        Opće uredbe o zaštiti podataka NN 42/2018, drugih zakona na temelju
        izvršavanje službene ovlasti voditelja obrade i odredbama UREDBE (EU)
        2016/679 EUROPSKOG PARLAMENTA I VIJEĆA od 27. travnja 2016. o zaštiti
        pojedinca u vezi s obradom osobnih podataka i o slobodnom kretanju
        takvih podataka.
      </SubtitleSC>
      <SubtitleSC>
        <b>MIHOLJAČKI VODOVOD D.O.O.</b> prepoznaje važnost zaštite privatnosti,
        sigurnosti i zaštite podataka za ispitanike stoga je naš cilj provesti
        zaštitu njihovih osobnih podataka za sve njih te implementirati sustav
        koji će to omogućiti.
      </SubtitleSC>
      <SubtitleSC>
        Svrha prikupljanja osobnih podataka od ispitanika temelji se na
        odrednicama zakona ili na privoli ispitanika. O svim podacima koje
        dobijemo iz drugih izvora redovito se obavještava ispitanik pa ukoliko
        su isti potrebni za daljnju obradu zatražit će se privola ispitanika.
        Ispitanik uvijek može povući privolu o obradi njegovih podataka u
        određenoj zbirci. Također može zatražiti brisanje podataka koristeći
        princip zaborava osobnog podataka.
      </SubtitleSC>
      <SubtitleSC>
        Zaštita privatnosti podataka ispitanika je trajna obveza voditelja i
        izvršitelja obrade te osoba koje su na bilo koji način u kontaktu sa
        podacima.
      </SubtitleSC>
      <SubtitleSC>
        Prikupljeni podaci ispitanika čuvaju se na primjeren način u skladu sa
        zakonom osiguravajući organizacijske, tehničke i programske mjere
        zaštite u skladu sa njihovom povjerljivosti. Svi podaci moraju biti
        zaštićeni od gubitka, uništenja, mijenjanja/krivotvorenja, manipulacije
        i nedozvoljenog pristupa, kao i od nedozvoljenog razotkrivanja.
        Prikupljeni podaci neće se prenositi trećim osobama bez zakonskog
        utemeljenja ili izričitog dopuštenja ispitanika.
      </SubtitleSC>
      <SubtitleSC>
        Sve što ispitanik smatra ne primjerenim u obradi osobnih podataka treba
        obavijestiti službenika za zaštitu podataka pa će se poduzeti
        odgovarajuće korektivne mjere.
      </SubtitleSC>
      <SubtitleSC>
        Prikupljanje i obrada podatke od maloljetnika, mlađih od 16 godina,
        dopušteno je samo uz zakonsko dopuštenje ili privolu roditelja ili
        zakonskog staratelja.
      </SubtitleSC>
      <SubtitleSC>
        Ova izjava o politici zaštite osobnih podataka ispitanika javno će se
        obznaniti, a obvezuje sve zaposlenike da je se pridržavaju i provode u
        praksi. Svako njeno kršenje tretirat će se kao teža povreda radne
        dužnosti.
      </SubtitleSC>
      <ul>
        <li>
          <SubtitleSC>
            <NavbarLink href="https://www.miholjacki-vodovod.hr/procedura-obrade-zahtjeva">
              Procedura obrade zahtjeva ispitanika za uvid u osobne podatke
            </NavbarLink>
          </SubtitleSC>
        </li>
        <li>
          <SubtitleSC>
            <NavbarLink href="https://www.miholjacki-vodovod.hr/procedura-povreda">
              Procedura u slučaju povrede osobnih podataka ispitanika
            </NavbarLink>
          </SubtitleSC>
        </li>
        <li>
          <SubtitleSC>
            <NavbarLink href="https://www.miholjacki-vodovod.hr/privola-web-stranica">
              Privola za obradu podataka prikupljenih na Web stranicama
            </NavbarLink>
          </SubtitleSC>
        </li>
        <li>
          <SubtitleSC>
            <NavbarLink href="https://www.miholjacki-vodovod.hr/uputa-privoli-ispitanika">
              Uputa o privoli za ispitanika
            </NavbarLink>
          </SubtitleSC>
        </li>
        <li>
          <SubtitleSC>
            <NavbarLink href="https://www.miholjacki-vodovod.hr/kodeks-ponasanja">
              Kodeks ponašanja voditelja obrade podataka
            </NavbarLink>
          </SubtitleSC>
        </li>
      </ul>
      <SubtitleSC>Donji Miholjac, 23.05.2018.</SubtitleSC>
      <LinkBackWrapperSC>
        <a href="/">Povratak na prijavu</a>
      </LinkBackWrapperSC>
    </WrapperSC>
  );
};

export default PrivacyPolicy;
