let months = [
  "Siječanj",
  "Veljača",
  "Ožujak",
  "Travanj",
  "Svibanj",
  "Lipanj",
  "Srpanj",
  "Kolovoz",
  "Rujan",
  "Listopad",
  "Studeni",
  "Prosinac",
];

export const formatChartData = (data, year) => {
  let formatedData = [];

  for (let i = 0; i < 12; i++) {
    const month = data.find(
      (x) => parseInt(x.reading_date.substring(5, 7)) === i + 1
    );
    const isPresent = Boolean(month);
    const isBillIssued = isPresent && Boolean(month.bill_date);
    const amount =
      isPresent && isBillIssued ? parseFloat(month.bill_consumption) : null;
    let record = {
      name: months[i] + " " + year + ".",
      amt: amount,
      ...(((!isPresent && !isBillIssued) || !amount) && { empty: true }),
    };
    formatedData.push(record);
  }

  let largestNumber = formatedData.reduce((a, b) =>
    a.amt > b.amt ? a : b
  ).amt;

  const formatedDataWithEmptyAmounts = formatedData.map((r) => {
    if (r.empty) {
      return { ...r, amt: largestNumber };
    }
    return r;
  });

  return largestNumber ? formatedDataWithEmptyAmounts : null;
};

export const yAxisFormater = (number) => {
  if (number >= 1000000) {
    return (number / 1000000).toString().replace(".", ",") + "M";
  } else if (number >= 1000) {
    return (number / 1000).toString().replace(".", ",") + "K";
  } else {
    return number.toString().replace(".", ",");
  }
};
