import styled from "styled-components";

import { Switch } from "@mui/material";

export const SwitchSC = styled(Switch)`
  &.MuiSwitch-root {
    display: flex;
    align-items: center;
    justify-content: center;

    // Dot
    .MuiSwitch-thumb {
      width: 18px;
      height: 18px;
      color: ${({ theme }) => theme.palette.primary50};
      margin-top: 1px;
      margin-left: 1px;
    }

    // Bg
    .MuiSwitch-track {
      min-width: 46px;
      height: 24px;
      border-radius: 50px;
      background-color: ${({ theme }) => theme.palette.primary800};
      opacity: 1;
    }

    // Bg - when checked
    .Mui-checked + .MuiSwitch-track {
      background-color: ${({ theme }) => theme.palette.primary500};
      opacity: 1;
    }

    // Bg - disabled
    .Mui-disabled + .MuiSwitch-track {
      background-color: #a2a1a4;
      opacity: 1;
    }
  }
`;
