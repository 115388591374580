import * as yup from "yup";

export const registerSchema = yup.object().shape({
  oib: yup
    .string()
    .required("Upišite OIB")
    .matches(/^$|^\d{11}$/, "OIB mora sadržavati 11 znamenki"),
  user_code: yup
    .string()
    .required("Upišite šifru korisnika")
    .matches(/^$|^\d{6}$/, "MbV mora sadržavati 6 znamenki"),
  email: yup
    .string()
    .email("Upišite ispravnu e-mail adresu")
    .required("Upišite e-mail adresu"),
  password: yup
    .string()
    .required("Upišite lozinku")
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&_])[A-Za-z\d@$!%*#?&_]{8,}$/,
      "Lozinka je neispravna. Upiši minimalno 8 znakova, veliko slovo, malo slovo, broj i specijalni znak"
    ),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Lozinke se ne podudaraju"),
  terms: yup
    .bool()
    .oneOf(
      [true],
      "Uvjeti i pravila korištenja nisu označeni kao pročitani i prihvaćeni"
    ),
});

export const loginSchema = yup.object().shape({
  email: yup
    .string()
    .email("Upišite ispravnu e-mail adresu")
    .required("Upišite e-mail adresu"),
  password: yup.string().required("Upišite lozinku"),
});

export const forgotPasswordSchema = yup.object().shape({
  email: yup
    .string()
    .email("Upišite ispravnu e-mail adresu")
    .required("Upišite e-mail adresu"),
});

export const resetPasswordSchema = yup.object().shape({
  password: yup
    .string()
    .required("Lozinka je obavezna")
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&_])[A-Za-z\d@$!%*#?&_]{8,}$/,
      "Potrebno 8 znakova, veliko slovo, malo slovo, broj i specijalni znak"
    ),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Lozinke se ne podudaraju"),
});
