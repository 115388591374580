import { view } from "@risingstack/react-easy-state";
import { Outlet } from "react-router-dom";

import { auth } from "../stores/auth";

const ProtectedRoute = view(() => {
  return <>{auth.isLoggedIn ? <Outlet /> : null}</>;
});

export default ProtectedRoute;
