import { view } from "@risingstack/react-easy-state";
import { IconNoDataSC, MessageWrapperSC } from "./styled";

const NoData = view(({ variant, message, messageArray }) => {
  return (
    <MessageWrapperSC variant={variant}>
      <div>
        <IconNoDataSC />
        {message && <p>{message}</p>}
        {messageArray?.length &&
          messageArray.map((p) => {
            return <p style={{ marginBottom: "8px", marginTop: "8px" }}>{p}</p>;
          })}
      </div>
    </MessageWrapperSC>
  );
});

export default NoData;
