import { useEffect, useState } from "react";
import { view, autoEffect } from "@risingstack/react-easy-state";
import { Outlet, NavLink, useNavigate, useLocation } from "react-router-dom";

import { devices } from "../../stores/devices";

import Spinner from "../../parts/Spinner/Spinner";
import NoData from "../../parts/PageMessage/NoData";
import Drawer from "../../parts/Drawer/Drawer";

import useMediaQuery from "@mui/material/useMediaQuery";

import {
  TitleSC,
  LeftPageSideSC,
  DeviceListSC,
  DeviceCardSC,
  PageWrapperSC,
  RightPageSideSC,
  RightPageSideContainerSC,
  ButtonBillsBackSC,
  ArrowBackSC,
} from "./styled";

import { ColumnSpinnerSC } from "../../parts/styled";

const Dashboard = view(() => {
  const navigate = useNavigate();
  const location = useLocation();
  const [drawerOpen, setDrawerOpen] = useState(true);
  const [shouldNavigateForward, setShouldNavigateForward] = useState(true);
  const [firstRender, setFirstRender] = useState(true);
  const matches1024Up = useMediaQuery("(min-width:1024px)");
  const matches501Up = useMediaQuery("(min-width:501px)");

  autoEffect(async () => {
    if (!devices.allDevices && firstRender) {
      await devices.getDevices();
    }
  }, [location]);

  autoEffect(() => {
    if (
      matches501Up &&
      devices.allDevices?.length > 0 &&
      !devices.device &&
      location.pathname === "/" &&
      shouldNavigateForward
    ) {
      const firstDevId = devices.allDevices[0].measuring_place_code;
      navigate(`/vodomjeri/${firstDevId}`);
    }
  }, [matches501Up, shouldNavigateForward]);

  autoEffect(() => {
    if (devices.device && shouldNavigateForward) {
      setShouldNavigateForward(false);
    }
  });

  useEffect(() => {
    setFirstRender(false);
    return () => {
      devices.allDevices = null;
    };
  }, []);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleDevicesBackLink = () => {
    devices.device = null;
    navigate("/");
  };

  return (
    <PageWrapperSC>
      <Drawer
        breakpoint={matches501Up && !matches1024Up}
        open={drawerOpen}
        onClose={toggleDrawer}
      >
        {(!location.pathname.startsWith("/vodomjeri/") ||
          matches501Up ||
          matches1024Up) && (
          <LeftPageSideSC>
            <TitleSC>Vodomjeri</TitleSC>
            {devices.isLoadingGet ? (
              <ColumnSpinnerSC>
                <Spinner />
              </ColumnSpinnerSC>
            ) : devices.allDevices?.length === 0 ? (
              <NoData
                messageArray={[
                  "Trenutno nema mjesta potrošnje.",
                  "Sinkronizacija podataka je započeta i može potrajati. Molimo pokušajte kasnije.",
                ]}
              />
            ) : devices.allDevices?.length > 0 ? (
              <DeviceListSC>
                {devices.allDevices.map((dev) => {
                  return (
                    <DeviceCardSC
                      key={dev.id}
                      component={NavLink}
                      to={`/vodomjeri/${dev.measuring_place_code}`}
                      onClick={() => setDrawerOpen(false)}
                    >
                      <header>
                        <h3>Mjesto potrošnje</h3>
                        <h3>{dev.measuring_place_code}</h3>
                      </header>
                      <div>
                        <p>
                          {dev.Street.name + " " + dev.street_number}
                          {dev.City && ", " + dev.City.name}
                        </p>
                      </div>
                      <footer>
                        {dev.measuring_device_code && (
                          <>
                            <h4>Vodomjer br.</h4>
                            <h3>{dev.measuring_device_code}</h3>
                          </>
                        )}
                      </footer>
                    </DeviceCardSC>
                  );
                })}
              </DeviceListSC>
            ) : null}
          </LeftPageSideSC>
        )}
      </Drawer>
      {(location.pathname.startsWith("/vodomjeri/") || matches501Up) && (
        <RightPageSideSC>
          <RightPageSideContainerSC>
            {matches501Up && !matches1024Up && (
              <ButtonBillsBackSC onClick={toggleDrawer}>
                <ArrowBackSC />
                Vodomjeri
              </ButtonBillsBackSC>
            )}
            {!matches501Up && (
              <ButtonBillsBackSC onClick={handleDevicesBackLink}>
                <ArrowBackSC />
                Vodomjeri
              </ButtonBillsBackSC>
            )}
            <Outlet />
          </RightPageSideContainerSC>
        </RightPageSideSC>
      )}
    </PageWrapperSC>
  );
});

export default Dashboard;
