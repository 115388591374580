import { useEffect } from "react";
import { view, autoEffect } from "@risingstack/react-easy-state";
import { useSearchParams, useNavigate } from "react-router-dom";

import { payment } from "../../stores/payment";

import {
  IconPaySuccessSC,
  PaymentInnerWrapperSC,
  PaymentText,
  PaymentTitle,
  PaymentWrapperSC,
} from "./styled";

import Spinner from "../../parts/Spinner/Spinner";

let timeout;

const PaySuccess = view(() => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const key = searchParams.get("key");
  const idArray = searchParams.get("bill")?.split(",");

  autoEffect(async () => {
    if (!key || idArray.length === 0) {
      navigate("/racuni");
    } else {
      await payment.confirmPayment(key, idArray);
      if (payment.isConfirmError) {
        timeout = setTimeout(() => {
          navigate(`/racuni`, {
            replace: true,
          });
        }, 3000);
      }
      if (payment.isConfirmSuccessfull) {
        if (idArray.length === 1) {
          timeout = setTimeout(() => {
            navigate(`/racuni/${idArray[0]}`, {
              replace: true,
              state: { redirectId: idArray[0] },
            });
          }, 3000);
        } else {
          timeout = setTimeout(() => {
            navigate(`/racuni`, {
              replace: true,
            });
          }, 3000);
        }
      }
    }
  }, []);

  useEffect(() => {
    return () => {
      payment.isConfirmError = false;
      payment.isConfirmSuccessfull = false;
      clearTimeout(timeout);
    };
  }, []);

  return (
    <PaymentWrapperSC>
      {payment.isLoadingConfirm ? (
        <Spinner sx={{ margin: "auto", color: "primary500" }} />
      ) : (
        <PaymentInnerWrapperSC>
          {payment.isConfirmError ? (
            <PaymentTitle>Došlo je do pogreške.</PaymentTitle>
          ) : (
            payment.isConfirmSuccessfull && (
              <>
                <PaymentTitle>
                  {idArray.length === 1 && "Račun uspješno plaćen."}
                  {idArray.length > 1 && "Računi uspješno plaćeni."}
                </PaymentTitle>
                <IconPaySuccessSC />
              </>
            )
          )}
          <PaymentText>Uskoro ćete biti preusmjereni na račune.</PaymentText>
        </PaymentInnerWrapperSC>
      )}
    </PaymentWrapperSC>
  );
});

export default PaySuccess;
