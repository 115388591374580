let months = [
  { key: "01", value: "siječanj" },
  { key: "02", value: "veljača" },
  { key: "03", value: "ožujak" },
  { key: "04", value: "travanj" },
  { key: "05", value: "svibanj" },
  { key: "06", value: "lipanj" },
  { key: "07", value: "srpanj" },
  { key: "08", value: "kolovoz" },
  { key: "09", value: "rujan" },
  { key: "10", value: "listopad" },
  { key: "11", value: "studeni" },
  { key: "12", value: "prosinac" },
];

export const fromatBillDateString = (data) => {
  const month = data.reading_date.substring(5, 7);
  const year = data.reading_date.substring(0, 4);

  const monthObject = months.find((m) => m.key === month);
  return `${monthObject.value} ${year}.`;
};
