import styled from "styled-components";
import { Box } from "@mui/material";

export const WrapperSC = styled(Box)`
  height: auto;
  margin: 0px 20px;
  @media (max-width: 1024px) {
    overflow-y: hidden;
  }
`;

export const TitlePrimarySC = styled.h1`
  font-family: ${({ theme }) => theme.fonts.h600};
  color: ${({ theme }) => theme.palette.primary800};
  font-size: 28px;
  line-height: 36px;
  letter-spacing: 0.56px;
  margin: 40px 0;
`;

export const SubtitleSC = styled.h3`
  font-family: ${({ theme }) => theme.fonts.b400};
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: ${({ theme }) => theme.palette.primary800};
  margin-bottom: 20px;
`;
export const ListItemSC = styled.li`
  font-family: ${({ theme }) => theme.fonts.b400};
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: ${({ theme }) => theme.palette.primary600};

  margin-bottom: 20px;
`;

export const NavbarLink = styled.a`
  text-decoration: none;
  color: rgb(85, 26, 139);
  &:active {
    color: blue;
  }
`;

export const LinkBackWrapperSC = styled(Box)`
  a {
    font-family: ${({ theme }) => theme.fonts.b700};
    font-size: 14px;
    line-height: 24px;
    color: ${({ theme }) => theme.palette.primary500};
  }
  margin-bottom: 20px;
`;
