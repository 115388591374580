import styled from "styled-components";

import { Button } from "@mui/material";

export const ButtonSC = styled(Button)`
  &.MuiButton-root {
    background-color: ${({ theme, colbg }) => theme.palette[colbg]};
    background-color: ${({ outlined }) => outlined && "transparent"};
    border: ${({ theme, colbg }) => `2px solid ${theme.palette[colbg]}`};
    border-radius: 50px;
    padding: ${({ theme, size }) =>
      size === "small" ? "2px 10px" : "6px 18px"};
    color: ${({ theme, coltext }) => theme.palette[coltext]};
    font-family: ${({ theme }) => theme.fonts.b700};
    font-size: 12px;
    letter-spacing: 0.12px;
    line-height: 24px;
    text-transform: none;

    &:disabled {
      background-color: #a2a1a4;
      border: 2px solid #a2a1a4;
      color: white;
    }

    &:hover {
      background-color: ${({ theme, colhov, colbg }) =>
        colhov ? theme.palette[colhov] : theme.palette[colbg]};
      background-color: ${({ outlined }) => outlined && "transparent"};
    }
  }

  .MuiIcon-root {
    margin-left: 6px;
    margin-right: 4px;
    background-color: ${({ theme, coltext }) => theme.palette[coltext]};
  }

  span {
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
