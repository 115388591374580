import { useEffect } from "react";
import { view, autoEffect } from "@risingstack/react-easy-state";
import { useParams, useNavigate } from "react-router-dom";
import { format } from "date-fns";

import { notifications } from "../../stores/notifications";

import {
  DateNotificationSC,
  MessageNotificationSC,
  TitleNotificationSC,
} from "./styled";

const NotificationDetails = view(() => {
  const navigate = useNavigate();
  const { notificationId } = useParams();

  autoEffect(async () => {
    notifications.notification = null;
    if (notifications.allNotifications?.length > 0) {
      notifications.notification = notifications.allNotifications.find(
        (b) => b.id === notificationId
      );
      if (!notifications.notification) {
        navigate("/obavijesti");
      }
    }
  }, [notificationId]);

  useEffect(() => {
    return () => {
      notifications.notification = null;
    };
  }, []);

  return (
    <>
      {notifications.notification && (
        <>
          <TitleNotificationSC>
            {notifications.notification.heading}
          </TitleNotificationSC>
          <DateNotificationSC>
            {format(new Date(notifications.notification.created_at), "d.M.yyyy.")}
          </DateNotificationSC>
          <MessageNotificationSC>
            {notifications.notification.text}
          </MessageNotificationSC>
        </>
      )}
    </>
  );
});

export default NotificationDetails;
