import { useState } from "react";
import { view } from "@risingstack/react-easy-state";
import { Outlet, NavLink, useNavigate, useLocation } from "react-router-dom";

import useMediaQuery from "@mui/material/useMediaQuery";

import { notifications } from "../../stores/notifications";
import { formatDateString } from "./utils";

import Spinner from "../../parts/Spinner/Spinner";
import Modal from "../../parts/Modal/Modal";
import Button from "../../parts/Button/Button";
import NoData from "../../parts/PageMessage/NoData";
import Drawer from "../../parts/Drawer/Drawer";

import { CircularProgress } from "@mui/material";

import { ColumnSpinnerSC } from "../../parts/styled";

import {
  SettingsButtonSC,
  IconNotificationActiveSC,
  IconNotificationSC,
  IconWrapperSC,
  LeftPageSideSC,
  ModalContentSC,
  NotificationCardContentSC,
  NotificationCardSC,
  NotificationListSC,
  NotificationListSettingsSC,
  PageWrapperSC,
  RightPageSideContainerSC,
  RightPageSideSC,
  TitleSC,
  ButtonBillsBackSC,
  ArrowBackSC,
} from "./styled";

const Notifications = view(() => {
  const navigate = useNavigate();
  const location = useLocation();
  const [modalReadAllOpen, setModalReadAllOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(true);
  const matches1024Up = useMediaQuery("(min-width:1024px)");
  const matches501Up = useMediaQuery("(min-width:501px)");

  const handleButtonReadAll = () => {
    notifications.unreadCount > 0 && setModalReadAllOpen(true);
  };

  const handleConfirmReadAll = async () => {
    await notifications.readNotifications();
    if (notifications.isReadAllSuccessfull) {
      notifications.allNotifications.forEach((ntf) => {
        if (!ntf.read_at) {
          ntf.read_at = true;
        }
      });
      notifications.isReadAllSuccessfull = false;
    }
    setModalReadAllOpen(false);
  };

  const handleClickCard = async (id, readAt) => {
    if (!readAt) {
      await notifications.readNotification(id);
      if (notifications.isReadSingleSuccessfull) {
        notifications.allNotifications.find(
          (ntf) => ntf.id === id
        ).read_at = true;
        notifications.isReadSingleSuccessfull = false;
      }
    }
    if (drawerOpen) {
      toggleDrawer();
    }
  };

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleNotificationsBackLink = () => {
    notifications.notification = null;
    navigate("/obavijesti");
  };

  return (
    <PageWrapperSC>
      <Drawer
        breakpoint={matches501Up && !matches1024Up}
        open={drawerOpen}
        onClose={toggleDrawer}
      >
        {(!location.pathname.startsWith("/obavijesti/") ||
          matches501Up ||
          matches1024Up) && (
          <LeftPageSideSC>
            <TitleSC>Obavijesti</TitleSC>
            {notifications.isLoadingGet ? (
              <ColumnSpinnerSC>
                <Spinner />
              </ColumnSpinnerSC>
            ) : notifications.allNotifications?.length === 0 ? (
              <NoData message="Trenutno nema obavijesti." />
            ) : notifications.allNotifications?.length > 0 ? (
              <>
                <NotificationListSettingsSC>
                  <SettingsButtonSC onClick={handleButtonReadAll}>
                    Označi sve kao pročitano
                  </SettingsButtonSC>
                </NotificationListSettingsSC>
                <NotificationListSC>
                  {notifications.allNotifications.map((ntf) => {
                    return (
                      <NotificationCardSC
                        key={ntf.id}
                        component={NavLink}
                        to={`/obavijesti/${ntf.id}`}
                        className={ntf.read_at ? "seen" : "unseen"}
                        onClick={() => handleClickCard(ntf.id, ntf.read_at)}
                      >
                        <IconWrapperSC>
                          {!ntf.read_at ? (
                            <IconNotificationActiveSC />
                          ) : (
                            <IconNotificationSC />
                          )}
                        </IconWrapperSC>
                        <NotificationCardContentSC>
                          <h3>
                            {ntf.heading.length < 38
                              ? ntf.heading
                              : ntf.heading.substring(0, 38).trim() + "..."}
                          </h3>
                          <p>{formatDateString(ntf.created_at)}</p>
                        </NotificationCardContentSC>
                      </NotificationCardSC>
                    );
                  })}
                </NotificationListSC>
              </>
            ) : null}
          </LeftPageSideSC>
        )}
      </Drawer>
      {(location.pathname.startsWith("/obavijesti/") || matches501Up) && (
        <RightPageSideSC>
          <RightPageSideContainerSC>
            {matches501Up && !matches1024Up && (
              <ButtonBillsBackSC onClick={toggleDrawer}>
                <ArrowBackSC />
                Obavijesti
              </ButtonBillsBackSC>
            )}
            {!matches501Up && (
              <ButtonBillsBackSC onClick={handleNotificationsBackLink}>
                <ArrowBackSC />
                Obavijesti
              </ButtonBillsBackSC>
            )}
            <Outlet />
          </RightPageSideContainerSC>
        </RightPageSideSC>
      )}
      <Modal
        open={modalReadAllOpen}
        title="Označiti sve obavijesti kao pročitane?"
      >
        <ModalContentSC>
          <Button
            colbg="primary500"
            coltext="primary500"
            outlined="true"
            onClick={() => setModalReadAllOpen(false)}
          >
            Odustani
          </Button>
          <Button
            colbg="primary500"
            coltext="primary50"
            onClick={handleConfirmReadAll}
          >
            <span style={{ minWidth: "68px" }}>
              {notifications.isLoadingReadAll ? (
                <CircularProgress
                  size={20}
                  sx={{ color: "white" }}
                  thickness={6}
                />
              ) : (
                "Označi sve"
              )}
            </span>
          </Button>
        </ModalContentSC>
      </Modal>
    </PageWrapperSC>
  );
});

export default Notifications;
