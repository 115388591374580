import { useEffect } from "react";
import { view, autoEffect } from "@risingstack/react-easy-state";
import { useParams, useNavigate } from "react-router-dom";
import { format, isBefore } from "date-fns";

import { bills } from "../../stores/bills";
import { payment } from "../../stores/payment";

import Button from "../../parts/Button/Button";
import OnlineBill from "./OnlineBill";
import Number from "../../parts/Number/Number";

import { Tooltip, CircularProgress } from "@mui/material";
import {
  AddressSC,
  AddressSectionSC,
  ActionSectionSC,
  IconPaymentSC,
  IconDownloadSC,
  SummarySC,
  SummaryConsumptionSC,
  SummaryDateSC,
  SummaryTextLightSC,
  SummaryTotalSC,
  TitleBillSC,
  ConversionSC,
  ConversionTextSC,
} from "./styled";

const BillDetails = view(() => {
  const navigate = useNavigate();
  const { billId } = useParams();

  autoEffect(async () => {
    bills.bill = null;
    if (bills.allBills?.length > 0) {
      bills.bill = bills.allBills.find((b) => b.id === parseInt(billId));
      if (!bills.bill) {
        navigate("/racuni");
      }
    }
  }, [billId]);

  useEffect(() => {
    return () => {
      bills.bill = null;
    };
  }, []);

  const handlePayButton = async (amount, id) => {
    if (!payment.billIdLoading && !payment.isLoadingPayAll) {
      payment.billIdLoading = id;
      await payment.pay(parseFloat(amount), [id], window.location.origin);
      payment.billIdLoading = null;
    }
    if (payment.paymentUrl) {
      window.location = payment.paymentUrl;
    }
  };

  const handleDownloadPdf = () => {
    if (bills?.bill?.erp_bill_id) {
      bills.downloadPDF(bills.bill.erp_bill_id);
    }
  };

  return (
    <>
      {bills.bill?.id && (
        <>
          <TitleBillSC>Detalji računa</TitleBillSC>

          <OnlineBill />

          <AddressSectionSC>
            <AddressSC>
              <div>
                <h3>Adresa mjesta potrošnje</h3>
                <p>
                  {bills.bill.Device.Street.name +
                    " " +
                    bills.bill.Device.street_number}
                </p>
                <p>
                  {bills.bill.Device.City &&
                    `${bills.bill.Device.City.postal_code} ${bills.bill.Device.City.name}`}
                </p>
              </div>
              <div>
                <h3>Adresa dostave računa</h3>
                {bills.bill.Device.e_bill ? (
                  <>
                    <p>Račun se dostavlja elektronski.</p>
                  </>
                ) : (
                  <>
                    <p>
                      {bills.bill.Device.Street.name +
                        " " +
                        bills.bill.Device.street_number}
                    </p>
                    <p>
                      {bills.bill.Device.City &&
                        `${bills.bill.Device.City.postal_code} ${bills.bill.Device.City.name}`}
                    </p>
                  </>
                )}
              </div>
            </AddressSC>
            <div>
              <h3>Broj mjesta potrošnje</h3>
              <p>{bills.bill.Device.measuring_place_code}</p>
            </div>
          </AddressSectionSC>
          <SummarySC>
            <div>
              <SummaryTextLightSC>Datum dospijeća</SummaryTextLightSC>
              <SummaryDateSC>
                {format(new Date(bills.bill.bill_date), "d.M.yyyy.")}
              </SummaryDateSC>
            </div>
            <div>
              <SummaryTextLightSC>Potrošnja</SummaryTextLightSC>
              <SummaryConsumptionSC>
                <Number
                  className="consumption"
                  value={bills.bill.bill_consumption}
                  decimalScale={3}
                />{" "}
                <span>
                  m<sup>3</sup>
                </span>
              </SummaryConsumptionSC>
            </div>
            <div>
              <SummaryTextLightSC align="right">Ukupno</SummaryTextLightSC>
              <SummaryTotalSC>
                {isBefore(
                  new Date(bills.bill.bill_date),
                  new Date(2023, 1, 1)
                ) ? (
                  <>
                    <p className="hrk">
                      <Number
                        className={
                          bills.bill.bill_state === "unpaid"
                            ? "hrk-num unpaid"
                            : "hrk-num"
                        }
                        value={bills.bill.bill_amount_hrk}
                        decimalScale={2}
                      />
                      <span>HRK</span>
                    </p>
                    <p className="eur">
                      <span className="bracket">(</span>
                      <Number
                        className="eur-num"
                        value={bills.bill.bill_amount_euro}
                        decimalScale={2}
                      />
                      <span>EUR</span>
                      <span className="bracket">)</span>
                    </p>
                  </>
                ) : (
                  <>
                    <p className="hrk">
                      <Number
                        className={
                          bills.bill.bill_state === "unpaid"
                            ? "hrk-num unpaid"
                            : "hrk-num"
                        }
                        value={bills.bill.bill_amount_euro}
                        decimalScale={2}
                      />
                      <span>EUR</span>
                    </p>
                    <p className="eur">
                      <span className="bracket">(</span>
                      <Number
                        className="eur-num"
                        value={bills.bill.bill_amount_hrk}
                        decimalScale={2}
                      />
                      <span>HRK</span>
                      <span className="bracket">)</span>
                    </p>
                  </>
                )}
              </SummaryTotalSC>
            </div>
          </SummarySC>
          <ConversionSC>
            <ConversionTextSC>*1 EUR = 7,5345 HRK</ConversionTextSC>
          </ConversionSC>
          <ActionSectionSC>
            <Button
              colbg="neutral50"
              coltext="primary900"
              endIcon={<IconDownloadSC />}
              sx={{ marginRight: "20px" }}
              onClick={handleDownloadPdf}
              disabled={bills.isLoadingPDF}
            >
              Preuzmi račun
            </Button>
            {bills.bill.bill_state === "unpaid" ? (
              <Button
                colbg="primary500"
                coltext="neutral100"
                endIcon={<IconPaymentSC />}
                onClick={() =>
                  handlePayButton(bills.bill.bill_amount_euro, bills.bill.id)
                }
              >
                <span style={{ minWidth: "72px" }}>
                  {bills.bill.bill_state === "unpaid" &&
                  (payment.billIdLoading === bills.bill.id ||
                    payment.isLoadingPayAll) ? (
                    <CircularProgress
                      size={20}
                      sx={{ color: "white" }}
                      thickness={6}
                    />
                  ) : (
                    "Plati račun"
                  )}
                </span>
              </Button>
            ) : (
              <Tooltip title="Račun je plaćen" placement="bottom" arrow>
                <span>
                  <Button
                    colbg="primary500"
                    coltext="neutral100"
                    endIcon={<IconPaymentSC />}
                    disabled={true}
                  >
                    <span style={{ minWidth: "72px" }}>Plati račun</span>
                  </Button>
                </span>
              </Tooltip>
            )}
          </ActionSectionSC>
        </>
      )}
    </>
  );
});

export default BillDetails;
