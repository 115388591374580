import styled from "styled-components";

import { Box, Icon, Avatar } from "@mui/material";

import LogoutIconSvg from "../../assets/img/logout_icon.svg";
import SettingsIconSvg from "../../assets/img/settings_icon.svg";
import NotificationIconSvg from "../../assets/img/notification_icon.svg";
import DeleteIconSvg from "../../assets/img/delete_icon.svg";
import VerifiedIconSvg from "../../assets/img/verified_icon.svg";
import MailSendImageSvg from "../../assets/img/success_mail.svg";

export const WrapperSC = styled(Box)`
  display: flex;
  height: 100%;
`;

export const LeftPageSideSC = styled(Box)`
  background-color: ${({ theme }) => theme.palette.primary900};
  display: flex;
  flex-direction: column;
  width: 392px;
  min-height: 100%;
  height: fit-content;
  padding: 0 24px 44px 24px;

  @media (max-width: 800px) {
    width: 100%;
  }
`;

export const TitlePrimarySC = styled.h1`
  font-family: ${({ theme }) => theme.fonts.b400};
  font-weight: 400;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: 0.56px;
  color: ${({ theme }) => theme.palette.primary50};
  margin: 40px 0;
`;

export const AvatarLargeSC = styled(Avatar)`
  margin-bottom: 24px;
  &.MuiAvatar-root {
    width: 104px;
    height: 104px;
    background-color: ${({ theme }) => theme.palette.primary800};
    color: ${({ theme }) => theme.palette.neutral50};
    font-family: ${({ theme }) => theme.fonts.h600};
    font-size: 44px;
  }
`;

export const SubsectionSC = styled(Box)`
  margin-bottom: 40px;
`;

export const SubtitleSC = styled.h3`
  font-family: ${({ theme }) => theme.fonts.b700};
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.12px;
  color: ${({ theme, color }) =>
    color === "black" ? theme.palette.primary800 : theme.palette.primary200};
  cursor: ${({ hoverable }) => hoverable && "pointer"};

  @media (max-width: 800px) {
    color: ${({ theme }) => theme.palette.primary500};
  }
`;

export const ParagraphSC = styled.p`
  font-family: ${({ theme }) => theme.fonts.b400};
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.12px;
  color: ${({ theme, color }) =>
    color === "black" ? theme.palette.primary800 : theme.palette.primary50};

  a {
    color: ${({ theme }) => theme.palette.primary500};
  }

  @media (max-width: 800px) {
    color: ${({ theme }) => theme.palette.primary50};
  }
`;

export const VersionAndLogoutSC = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: auto;

  div:first-child {
    margin-right: 24px;
  }
`;

export const VersionTextSC = styled.p`
  font-family: ${({ theme }) => theme.fonts.h600};
  font-size: 10px;
  line-height: 16px;
  color: ${({ theme }) => theme.palette.primary50};
`;

export const IconLogoutSC = styled(Icon)`
  &.MuiIcon-root {
    width: 24px;
    height: 24px;
    -webkit-mask: url(${LogoutIconSvg}) no-repeat center;
    mask: url(${LogoutIconSvg}) no-repeat center;
  }
`;

export const RightPageSideSC = styled(Box)`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px;
  position: relative;
  height: 100%;

  div {
    max-width: 424px;
  }

  @media (max-width: 800px) {
    display: none;
  }
`;

export const IconSettingsLargeSC = styled(Icon)`
  position: absolute;
  top: 52px;
  right: 48px;

  &.MuiIcon-root {
    width: 116.6px;
    height: 120px;
    -webkit-mask: url(${SettingsIconSvg}) no-repeat center;
    -webkit-mask-size: contain;
    mask: url(${SettingsIconSvg}) no-repeat center;
    mask-size: contain;
    background-color: ${({ theme }) => theme.palette.primary100};

    @media (max-width: 1024px) {
      display: none;
    }
  }
`;

export const TitleSecondarySC = styled.h2`
  font-family: ${({ theme }) => theme.fonts.b400};
  font-weight: 400;
  font-size: 32px;
  line-height: 56px;
  letter-spacing: 0.56px;
  color: ${({ theme }) => theme.palette.primary800};
  margin-bottom: 40px;
`;

export const SettingsContentSC = styled(Box)`
  display: flex;
  flex-direction: column;
`;

export const SettingsSubsectionSC = styled(Box)`
  display: flex;
  margin-bottom: 40px;
`;

export const SettingsSubsectionInnerSC = styled(Box)`
  padding-left: 34px;
  display: ${({ flexed }) => flexed && "flex"};
  align-items: center;

  div {
    padding-right: 12px;
  }

  @media (max-width: 800px) {
    width: 100%;
    justify-content: space-between;
    padding-left: 24px;
  }
`;

export const IconNotificationSC = styled(Icon)`
  margin-top: 2px;
  margin-left: 2px;
  &.MuiIcon-root {
    width: 20px;
    height: 20px;
    -webkit-mask: url(${NotificationIconSvg}) no-repeat center;
    mask: url(${NotificationIconSvg}) no-repeat center;
    background-color: ${({ theme }) => theme.palette.primary500};
  }
`;

export const IconDeleteSC = styled(Icon)`
  margin-top: 3px;
  margin-left: 5px;
  &.MuiIcon-root {
    width: 14px;
    height: 18px;
    -webkit-mask: url(${DeleteIconSvg}) no-repeat center;
    mask: url(${DeleteIconSvg}) no-repeat center;
    background-color: ${({ theme }) => theme.palette.primary500};
  }
`;

export const IconVerifiedSC = styled(Icon)`
  margin-top: 1px;
  margin-left: 3px;
  &.MuiIcon-root {
    width: 18px;
    height: 21.8px;
    -webkit-mask: url(${VerifiedIconSvg}) no-repeat center;
    mask: url(${VerifiedIconSvg}) no-repeat center;
    background-color: ${({ theme }) => theme.palette.primary500};
  }
`;

export const ModalContentSC = styled(Box)`
  display: flex;
  justify-content: space-between;
`;

export const TextSC = styled.p`
  font-family: ${({ theme }) => theme.fonts.b400};
  font-size: 14px;
  line-height: 24px;
  color: ${({ theme }) => theme.palette.primary800};
  margin-top: -20px;
  margin-bottom: 20px;
  text-align: center;
`;

export const IconMailSendSC = styled.img.attrs({
  src: MailSendImageSvg,
})`
  width: 108.6px;
  height: 96px;
  margin-bottom: 40px;
`;
