import styled from "styled-components";

import { Icon } from "@mui/material";
import { Link } from "react-router-dom";
import PrivacyIconSvg from "../../assets/img/privacy_icon.svg";

export const FooterSC = styled.footer`
  min-height: 40px;
  background-color: ${({ theme }) => theme.palette.primary900};
  width: 100%;
  padding: 0 24px;
  position: absolute;
  bottom: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  font-family: ${({ theme }) => theme.fonts.h600};
  font-size: 10px;

  @media (max-width: 600px) {
    flex-direction: column;
    justify-content: space-evenly;
    align-items: start;
    min-height: 80px;
  }
`;

export const TextPrivacySC = styled.div`
  display: flex;
  align-items: center;
  span {
    font-size: 18px;
    margin-right: 9px;

    @media (max-width: 600px) {
      margin-left: 2px;
    }
  }
`;

export const FooterLinksSC = styled.div`
  display: flex;
  align-items: center;

  div {
    display: flex;
    align-items: center;
    margin-right: 12px;
    span {
      margin-left: 14px;

      @media (max-width: 600px) {
        margin-left: 0;
        margin-right: 8px;
      }
    }
  }
`;

export const IconPrivacySC = styled(Icon)`
  &.MuiIcon-root {
    width: 18px;
    height: 22px;
    -webkit-mask: url(${PrivacyIconSvg}) no-repeat center;
    mask: url(${PrivacyIconSvg}) no-repeat center;
    background-color: ${({ theme }) => theme.palette.primary50};
    transform: scale(0.8);
  }
`;

export const NavbarLink = styled(Link)`
  text-decoration: none;
  color: white;
  &:active {
    color: ${({ theme }) => theme.palette.primary200};
  }
`;
