import { format } from "date-fns";

export const formatDateString = (date) => {
  const now = Date.now();
  const notificationDate = new Date(date.substring(0, 10)).getTime();
  const difference = now - notificationDate;
  const msInDay = 86400000;
  let stringToReturn;

  if (difference < msInDay) {
    stringToReturn = "Danas";
  } else if (difference < msInDay * 2) {
    stringToReturn = "Jučer";
  } else if (difference < msInDay * 3) {
    stringToReturn = "Prije 2 dana";
  } else if (difference < msInDay * 4) {
    stringToReturn = "Prije 3 dana";
  } else if (difference < msInDay * 5) {
    stringToReturn = "Prije 4 dana";
  } else if (difference < msInDay * 6) {
    stringToReturn = "Prije 5 dana";
  } else {
    stringToReturn = format(new Date(date), "d.M.yyyy.");
  }

  return stringToReturn;
};
