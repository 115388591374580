import { view } from "@risingstack/react-easy-state";

import { auth } from "../../stores/auth";

import Toggler from "../../parts/Toggler/Toggler";

import {
  IconDeleteSC,
  IconNotificationSC,
  ParagraphSC,
  SettingsContentSC,
  SettingsSubsectionInnerSC,
  SettingsSubsectionSC,
  SubtitleSC,
} from "./styled";

const Settings = view(({ setModalRequestOpen }) => {
  const handleChangeToggler = async () => {
    await auth.updateUser(!auth.userDetails.mail_notifiable);
    if (auth.isUpdateSuccess) {
      auth.userDetails.mail_notifiable = !auth.userDetails.mail_notifiable;
    }
    auth.isUpdateSuccess = false;
  };

  return (
    <SettingsContentSC>
      <SettingsSubsectionSC>
        <IconNotificationSC />
        <SettingsSubsectionInnerSC flexed>
          <div>
            <SubtitleSC color="black" hoverable onClick={handleChangeToggler}>
              Dozvoli slanje obavijesti putem email-a
            </SubtitleSC>
            <ParagraphSC color="black">
              {auth.userDetails?.mail_notifiable
                ? "Aplikacija dopušta slanje obavijesti putem email-a"
                : "Aplikacija ne dopušta slanje obavijesti email-a"}
            </ParagraphSC>
          </div>
          <Toggler
            checked={Boolean(auth.userDetails?.mail_notifiable)}
            onChange={handleChangeToggler}
            disabled={auth.isLoadingUpdate}
          />
        </SettingsSubsectionInnerSC>
      </SettingsSubsectionSC>
      <SettingsSubsectionSC>
        <IconDeleteSC />
        <SettingsSubsectionInnerSC>
          <SubtitleSC
            color="black"
            hoverable
            onClick={() => setModalRequestOpen(true)}
          >
            Uklonite podatke
          </SubtitleSC>
          <ParagraphSC color="black">
            Uklanja sve podatke o aplikaciji
          </ParagraphSC>
        </SettingsSubsectionInnerSC>
      </SettingsSubsectionSC>
    </SettingsContentSC>
  );
});

export default Settings;
