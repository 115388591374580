import { useState, useEffect } from "react";
import { view } from "@risingstack/react-easy-state";

import useMediaQuery from "@mui/material/useMediaQuery";

import { yAxisFormater } from "./utils";

import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

import Number from "../../parts/Number/Number";

import {
  TooltipWrapperSC,
  TooltipTitleSC,
  TooltipAmountSC,
  TooltipDateSC,
} from "./styled";

const CustomTooltip = view(({ active, payload, label }) => {
  if (payload.length > 0 && !payload[0].payload?.empty) {
    return (
      <TooltipWrapperSC>
        <TooltipTitleSC>Potrošnja</TooltipTitleSC>
        <TooltipAmountSC>
          <Number
            className="amount"
            value={payload[0].payload?.amt}
            decimalScale={3}
          />
          <span>
            m<sup>3</sup>
          </span>
        </TooltipAmountSC>
        <TooltipDateSC>{payload[0].payload?.name}</TooltipDateSC>
        <span className="box-tip"></span>
      </TooltipWrapperSC>
    );
  }
  return null;
});

const Chart = view(({ data }) => {
  const [activeBarIndex, setActiveBarIndex] = useState();
  const [toolTipYPosition, setToolTipYPosition] = useState({});
  const [barWidth, setBarWidth] = useState();
  const matches1600Up = useMediaQuery("(min-width:1600px)");

  useEffect(() => {
    const barCharts = document.querySelectorAll(".recharts-bar-rectangle");
    if (!barCharts || activeBarIndex === undefined) return;
    // Init tooltip values
    const barChart = barCharts[activeBarIndex];
    setToolTipYPosition(barChart.getBoundingClientRect().height);
  }, [activeBarIndex]);

  useEffect(() => {
    if (matches1600Up) {
      setBarWidth(20);
    } else {
      setBarWidth(16);
    }
  }, [matches1600Up]);

  return (
    <ResponsiveContainer minWidth={280} width="99%" height={312}>
      <BarChart
        margin={{ top: 16, right: 2, bottom: 0, left: 6 }}
        data={data}
        onMouseMove={(e) => {
          setActiveBarIndex(e.activeTooltipIndex);
        }}
      >
        <defs>
          <linearGradient
            id="linear"
            x1="0%"
            y1="100%"
            x2="100%"
            y2="0%"
            gradientTransform="rotate(-24)"
          >
            <stop offset="0%" stopColor="#00BAFF" />
            <stop offset="100%" stopColor="#CCF1FF" />
          </linearGradient>
        </defs>
        <XAxis
          dataKey={({ name }) =>
            matches1600Up ? name.slice(0, -6) : name.charAt(0)
          }
          height={36}
          tick={{
            dy: 16,
            stroke: "#8B8A8D",
            fontSize: 10,
            letterSpacing: "1px",
            fontFamily: "PoppinsThin",
          }}
          axisLine={false}
          tickLine={false}
        />
        <YAxis
          tickCount={7}
          width={42}
          tick={{
            dx: -12,
            stroke: "#8B8A8D",
            fontSize: 10,
            letterSpacing: "1.2px",
            fontFamily: "PoppinsThin",
          }}
          tickFormatter={yAxisFormater}
          axisLine={false}
          tickLine={false}
        />
        {/* <Tooltip cursor={false} content={<CustomTooltip />} /> */}
        <CartesianGrid
          strokeDasharray="4"
          strokeWidth={2}
          stroke="#F4F3F6"
          vertical={false}
        />
        <Tooltip
          cursor={false}
          content={<CustomTooltip />}
          position={{ y: 200 - toolTipYPosition }}
          offset={-60}
          allowEscapeViewBox={{ x: true, y: true }}
        />
        <Bar dataKey="amt" barSize={barWidth} radius={16}>
          {data?.map((month, index) => (
            <Cell
              key={index}
              fill={month.empty ? "#E5F8FF" : "url(#linear)"}
              stroke={month.empty ? "#CCF1FF" : ""}
            />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
});

export default Chart;
