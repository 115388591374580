import { view } from "@risingstack/react-easy-state";

import { devices } from "../../stores/devices";
import { bills } from "../../stores/bills";

import Modal from "../../parts/Modal/Modal";
import Button from "../../parts/Button/Button";
import { IconSuccessMRSC, TextSC } from "./styled";

const MeasurementReportSuccessModal = view(({ open, setIsOpen }) => {
  const handleClose = async () => {
    setIsOpen(false);
    if (devices.device) {
      await devices.getDevices();
    }
    if (bills.bill) {
      await bills.getBills();
    }
  };
  return (
    <Modal open={open} title="Samoočitanje uspješno dostavljeno!">
      <IconSuccessMRSC />
      <TextSC>
        Zahvaljujemo Vam što koristite opciju dostave stanja brojila. Nakon
        obrade podataka, račun ćete dobiti na temelju unesenog stanja.
      </TextSC>
      <Button colbg="primary500" coltext="primary50" onClick={handleClose}>
        Povratak na aplikaciju
      </Button>
    </Modal>
  );
});

export default MeasurementReportSuccessModal;
