import { view } from "@risingstack/react-easy-state";

import { CircularProgress } from "@mui/material";

const Spinner = view(({...rest}) => {
  return (
    <CircularProgress size={40} sx={{ color: "primary500" }} thickness={4} {...rest} />
  );
});

export default Spinner;
