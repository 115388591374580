import { store } from "@risingstack/react-easy-state";
import { API_ENDPOINT } from "../config/constants.js";
import { authHeader } from "./auth";

export const bills = store({
  isLoadingGet: false,
  allBills: null,
  allTransformedBills: null,
  bill: null,
  isLoadingGetABill: false,
  aBill: null,
  isLoadingUpdate: false,
  isUpdateSuccessfull: false,
  isLoadingPDF: false,

  async getBills() {
    bills.isLoadingGet = true;

    try {
      const response = await fetch(`${API_ENDPOINT}/system/user-bills`, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          ...authHeader(),
        },
      });

      if (!response.ok) {
        throw Error(response.statusText);
      }

      const responseJson = await response.json();
      bills.allBills = responseJson.data;
      bills.isLoadingGet = false;
    } catch (error) {
      bills.isLoadingGet = false;
      console.log(error);
    }
  },

  async getBill(id) {
    bills.isLoadingGetABill = true;

    try {
      const response = await fetch(`${API_ENDPOINT}/system/user-bill/${id}`, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          ...authHeader(),
        },
      });

      if (!response.ok) {
        throw Error(response.statusText);
      }

      const responseJson = await response.json();
      bills.aBill = responseJson;
      bills.isLoadingGetABill = false;
    } catch (error) {
      bills.isLoadingGetABill = false;
      console.log(error);
    }
  },

  async downloadPDF(id) {
    bills.isLoadingPDF = true;

    try {
      const response = await fetch(`${API_ENDPOINT}/system/pdf-bill/${id}`, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          ...authHeader(),
        },
      });
      console.log(response);

      if (!response.ok) {
        throw Error(response.statusText);
      }
      console.log(bills.bill);
      const res = await response.blob();
      const href = URL.createObjectURL(new Blob([res]));
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute(
        "download",
        `racun${
          bills?.bill?.id ? bills.bill.id : ""
        }.pdf`
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);

      bills.isLoadingPDF = false;
    } catch (error) {
      bills.isLoadingPDF = false;
      console.log(error);
    }
  },
});
