import styled from "styled-components";

import {
  Box,
  Icon,
  IconButton,
  Avatar,
  Badge as BadgeMUI,
} from "@mui/material";

import VodovodLogoSvg from "../../assets/img/vodovod_logo_2.svg";
import HomeIconSvg from "../../assets/img/home_icon.svg";
import BillsIconSvg from "../../assets/img/bills_icon.svg";
import SettingsIconSvg from "../../assets/img/settings_icon.svg";
import InfoIconSvg from "../../assets/img/info_icon.svg";
import NotificationIconSvg from "../../assets/img/notification_icon.svg";
import LogoutIconSvg from "../../assets/img/logout_icon.svg";
import LogoFullSvg from "../../assets/img/logo_full_2.svg";

// Unautentcated
export const SidebarUnauthenticatedSC = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 392px;
  min-height: 479px;
  background-color: ${({ theme }) => theme.palette.primary800};

  @media (max-width: 1024px) {
    min-height: 104px;
    min-width: 0;
    width: 100%;
  }
`;

export const LogoFullSC = styled(Icon)`
  &.MuiIcon-root {
    width: 160px;
    height: 90px;
    background: url(${LogoFullSvg}) no-repeat center;
    background-color: transparent;
  }
`;

// Authenticated
export const NavSC = styled.nav`
  min-height: 100vh;
  height: fit-content;
  background-color: ${({ theme }) => theme.palette.primary800};
  min-width: 64px;
  padding: 40px 0;
  display: flex;
  justify-content: center;
  z-index: ${({ theme }) => theme.zIndex.drawer + 1};

  @media (max-width: 1024px) {
    padding: 0;
    min-height: 64px;
    height: 64px;
    min-width: 100%;
  }
`;

export const NavWrapperSC = styled(Box)`
  padding: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  a {
    padding: 0;
  }

  @media (max-width: 1024px) {
    padding: 0 24px;
    width: 100%;
    flex-direction: row;
  }
`;

export const LogoWrapperSC = styled(Box)`
  margin-bottom: 20px;

  @media (max-width: 1024px) {
    margin-bottom: 0;
  }

  @media (max-width: 500px) {
    display: none;
  }
`;

export const NavLinkListSC = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 0;

  & .MuiIconButton-root {
    margin-bottom: 25px;
  }

  @media (max-width: 1024px) {
    margin-left: auto;
    flex-direction: row;
    & .MuiIconButton-root {
      margin-bottom: 0;
      margin-right: 24px;
    }
  }

  @media (max-width: 500px) {
    .MuiIconButton-root:first-of-type {
    }
    .MuiIconButton-root:last-of-type {
    }
    flex: 1;
    justify-content: space-between;
  }
`;

export const IconButtonLinkSC = styled(IconButton)`
  &.MuiIconButton-root {
    background-color: ${({ theme, activesubroute }) =>
      activesubroute === "dashboard"
        ? theme.palette.primary500
        : "transparent"};
    width: 40px;
    height: 40px;
  }
  & .MuiIcon-root {
    background-color: ${({ theme, activesubroute }) =>
      activesubroute === "dashboard"
        ? theme.palette.primary50
        : theme.palette.primary600};
  }

  &:hover,
  &.active {
    &.MuiIconButton-root {
      background-color: ${({ theme }) => theme.palette.primary500};
    }
    & .MuiIcon-root {
      background-color: ${({ theme }) => theme.palette.primary50};
    }
  }
`;

export const IconLogoSC = styled(Icon)`
  &.MuiIcon-root {
    width: 40px;
    height: 40px;
    background: url(${VodovodLogoSvg}) no-repeat center;
    background-color: transparent;
  }
`;

export const IconHomeSC = styled(Icon)`
  &.MuiIcon-root {
    width: 18px;
    height: 17px;
    -webkit-mask: url(${HomeIconSvg}) no-repeat center;
    mask: url(${HomeIconSvg}) no-repeat center;
  }
`;

export const IconBillsSC = styled(Icon)`
  &.MuiIcon-root {
    width: 20px;
    height: 16px;
    -webkit-mask: url(${BillsIconSvg}) no-repeat center;
    mask: url(${BillsIconSvg}) no-repeat center;
  }
`;

export const IconSettingsSC = styled(Icon)`
  &.MuiIcon-root {
    width: 20px;
    height: 20px;
    -webkit-mask: url(${SettingsIconSvg}) no-repeat center;
    mask: url(${SettingsIconSvg}) no-repeat center;
    background-color: #999;
  }
`;

export const IconInfoSC = styled(Icon)`
  &.MuiIcon-root {
    width: 17px;
    height: 20px;
    -webkit-mask: url(${InfoIconSvg}) no-repeat center;
    mask: url(${InfoIconSvg}) no-repeat center;
  }
`;

export const IconNotificationSC = styled(Icon)`
  &.MuiIcon-root {
    width: 15px;
    height: 20px;
    -webkit-mask: url(${NotificationIconSvg}) no-repeat center;
    mask: url(${NotificationIconSvg}) no-repeat center;
  }
`;

export const Badge = styled(BadgeMUI)`
  position: relative;
  & .MuiBadge-badge {
    background-color: ${({ theme }) => theme.palette.danger500};
    color: ${({ theme }) => theme.palette.neutral0};
    font-size: 12px;
    font-family: ${({ theme }) => theme.fonts.h500};
    height: 16px;
    min-width: 16px;
    padding: 0 4px;
    border-radius: 50%;
    position: absolute;
    top: -4px;
    right: -4px;
  }
`;

export const IconLogoutSC = styled(Icon)`
  &.MuiIcon-root {
    width: 24px;
    height: 24px;
    -webkit-mask: url(${LogoutIconSvg}) no-repeat center;
    mask: url(${LogoutIconSvg}) no-repeat center;
    background-color: #999;
  }
`;

export const IconButtonAvatarSC = styled(IconButton)`
  &.MuiIconButton-root {
    padding: 2px;
    background-color: ${({ theme }) => theme.palette.primary500};
    &:hover {
      background-color: ${({ theme }) => theme.palette.primary500};
    }

    @media (max-width: 500px) {
      margin-left: 5%;
    }
  }
`;

export const AvatarSC = styled(Avatar)`
  &.MuiAvatar-root {
    width: 36px;
    height: 36px;
    background-color: ${({ theme }) => theme.palette.primary800};
    color: ${({ theme }) => theme.palette.neutral50};
    font-family: ${({ theme }) => theme.fonts.h600};
    font-size: 15px;
    &:hover {
      background-color: ${({ theme }) => theme.palette.primary500};
    }
  }

  &.active {
    background-color: ${({ theme }) => theme.palette.primary500};
  }
`;
