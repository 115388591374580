import styled from "styled-components";

import { Box } from "@mui/material";
import { ModalUnstyled } from "@mui/base";
export const ModalSC = styled(ModalUnstyled)`
  &.MuiModal-root {
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const ModalBackdropSC = styled(Box)`
  z-index: -1;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: ${({ theme }) => theme.palette.primary800};
  opacity: 0.4;
`;

export const ModalBoxSC = styled(Box)`
  outline: none;
  max-width: 562px;
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.neutral0};
  padding: 40px 100px;
  border-radius: 16px;
  margin: 24px;

  & > div {
    width: 100%;
  }

  @media (max-width: 600px) {
    padding: 40px;
  }
`;

export const ModalTitleSC = styled.h2`
  font-size: 24px;
  line-height: 38px;
  font-family: ${({ theme }) => theme.fonts.b400};
  font-weight: 400;
  color: ${({ theme }) => theme.palette.primary800};
  text-align: center;
  margin-bottom: 40px;
`;
