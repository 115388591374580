import { useEffect } from "react";
import { view } from "@risingstack/react-easy-state";
import { useNavigate } from "react-router-dom";
import {
  PaymentInnerWrapperSC,
  PaymentText,
  PaymentTitle,
  PaymentWrapperSC,
} from "./styled";

const PayCancel = view(() => {
  const navigate = useNavigate();

  useEffect(() => {
    const timeout = setTimeout(() => {
      navigate(`/racuni`, { replace: true });
    }, 3000);

    return () => clearTimeout(timeout);
  }, [navigate]);

  return (
    <PaymentWrapperSC>
      <PaymentInnerWrapperSC>
        <PaymentTitle>Plačanje poništeno.</PaymentTitle>
        <PaymentText>Uskoro ćete biti preusmjereni na račune.</PaymentText>
      </PaymentInnerWrapperSC>
    </PaymentWrapperSC>
  );
});

export default PayCancel;
