import { useState, useEffect, useRef } from "react";
import { view, autoEffect } from "@risingstack/react-easy-state";
import {
  Outlet,
  NavLink,
  useNavigate,
  useLocation,
  useParams,
} from "react-router-dom";
import { format, isBefore } from "date-fns";

import useMediaQuery from "@mui/material/useMediaQuery";

import { bills } from "../../stores/bills";
import { payment } from "../../stores/payment";
import { fromatBillDateString } from "./utils";

import Button from "../../parts/Button/Button";
import Spinner from "../../parts/Spinner/Spinner";
import NoData from "../../parts/PageMessage/NoData";
import Number from "../../parts/Number/Number";
import Drawer from "../../parts/Drawer/Drawer";

import { CircularProgress } from "@mui/material";

import {
  TitleSC,
  BillListSettingsSC,
  BillListSC,
  BillCardSC,
  BottomPartSC,
  DateAndButtonSC,
  FilterButtonSC,
  LeftPageSideSC,
  PageWrapperSC,
  RightPageSideSC,
  RightPageSideContainerSC,
  IconRuleSC,
  TopPartSC,
  TotalDebtSC,
  BodySC,
  TotalPriceSC,
  ButtonBillsBackSC,
  ArrowBackSC,
} from "./styled";
import { ColumnSpinnerSC } from "../../parts/styled";

const Bills = view(() => {
  const navigate = useNavigate();
  const location = useLocation();
  const listRef = useRef(null);
  const { billId } = useParams();
  const [shouldNavigateForward, setShouldNavigateForward] = useState(true);
  const [firstRender, setFirstRender] = useState(true);
  const [selectedFilter, setSelectedFilter] = useState("all");
  const [totalKunaDebt, setTotalKunaDebt] = useState(0);
  const [totalEuroDebt, setTotalEuroDebt] = useState(0);
  const [drawerOpen, setDrawerOpen] = useState(true);
  const matches1024Up = useMediaQuery("(min-width:1024px)");
  const matches501Up = useMediaQuery("(min-width:501px)");

  autoEffect(async () => {
    if (!bills.allBills && firstRender) {
      await bills.getBills();
      if (bills.allBills) {
        bills.allBills = bills.allBills.filter((b) => b.bill_date);
        const billsFiltered = bills.allBills.filter((b) => b.bill_date);
        bills.allTransformedBills = sortByDate(Array.from(billsFiltered));
      }
    }
  }, [location]);

  autoEffect(() => {
    if (
      matches501Up &&
      bills.allTransformedBills?.length > 0 &&
      !bills.bill &&
      location.pathname === "/racuni" &&
      shouldNavigateForward
    ) {
      const firstBillId = bills.allTransformedBills[0].id;
      navigate(`/racuni/${firstBillId}`);
    }
  }, [matches501Up, shouldNavigateForward]);

  autoEffect(() => {
    if (bills.bill && shouldNavigateForward) {
      setShouldNavigateForward(false);
    }
  });

  autoEffect(() => {
    if (
      bills.allTransformedBills?.length > 0 &&
      location.state?.redirectId &&
      listRef.current
    ) {
      setDrawerOpen(false);
      navigate(`/racuni/${location.state.redirectId}`, { replace: true });
      const redirectIndex = bills.allTransformedBills.findIndex(
        (el) => el.id === parseInt(billId)
      );
      const pixelsOffset = redirectIndex * 199;
      listRef.current?.scroll({ top: pixelsOffset, behavior: "smooth" });
    }
  }, [location]);

  autoEffect(() => {
    if (bills.allBills?.length > 0) {
      const kunaDebt = bills.allBills
        .filter((b) => b.bill_state === "unpaid")
        .map((b) => parseFloat(b.bill_amount_hrk))
        .reduce((prev, next) => prev + next, 0);
      const euroDebt = bills.allBills
        .filter((b) => b.bill_state === "unpaid")
        .map((b) => parseFloat(b.bill_amount_euro))
        .reduce((prev, next) => prev + next, 0);
      setTotalKunaDebt(kunaDebt);
      setTotalEuroDebt(euroDebt);
    }
  }, [bills.allBills]);

  useEffect(() => {
    setFirstRender(false);
    return () => {
      bills.allBills = null;
    };
  }, []);

  const sortByDate = (array) => {
    return array.sort(
      (a, b) => new Date(b.reading_date) - new Date(a.reading_date)
    );
  };

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleBillsBackLink = () => {
    bills.bill = null;
    navigate("/racuni");
  };

  const handleFilterAll = () => {
    const billsFiltered = bills.allBills.filter((b) => b.bill_date);
    bills.allTransformedBills = sortByDate(Array.from(billsFiltered));
    setSelectedFilter("all");
  };

  const handleFilterUnpaid = () => {
    const billsFiltered = bills.allBills.filter(
      (b) => b.bill_state === "unpaid"
    );
    bills.allTransformedBills = sortByDate(Array.from(billsFiltered));
    setSelectedFilter("unpaid");
  };

  const handleFilterPaid = () => {
    const billsFiltered = bills.allBills.filter((b) => b.bill_state === "paid");
    bills.allTransformedBills = sortByDate(Array.from(billsFiltered));
    setSelectedFilter("paid");
  };

  const handleClickCard = (e, billState) => {
    if (
      (!e.target.classList.contains("pay-button") &&
        !e.target.parentNode.classList.contains("pay-button")) ||
      billState !== "unpaid"
    ) {
      setDrawerOpen(false);
    }
  };

  const handlePayButton = async (amount, id) => {
    if (!payment.billIdLoading && !payment.isLoadingPayAll) {
      payment.billIdLoading = id;
      await payment.pay(parseFloat(amount), [id], window.location.origin);
      payment.billIdLoading = null;
    }
    if (payment.paymentUrl) {
      window.location = payment.paymentUrl;
    }
  };

  const handlePayAllButton = async () => {
    if (!payment.isLoadingPayAll) {
      payment.isLoadingPayAll = true;
      const unpaidBills = bills.allBills.filter(
        (b) => b.bill_state === "unpaid"
      );
      await payment.pay(
        totalEuroDebt,
        unpaidBills.map((b) => parseFloat(b.id)),
        window.location.origin
      );
      payment.isLoadingPayAll = false;
    }
    if (payment.paymentUrl) {
      window.location = payment.paymentUrl;
    }
  };

  return (
    <PageWrapperSC>
      <Drawer
        breakpoint={matches501Up && !matches1024Up}
        open={drawerOpen}
        onClose={toggleDrawer}
      >
        {(!location.pathname.startsWith("/racuni/") ||
          matches501Up ||
          matches1024Up) && (
          <LeftPageSideSC>
            <TitleSC>Računi</TitleSC>
            {bills.isLoadingGet ? (
              <ColumnSpinnerSC>
                <Spinner />
              </ColumnSpinnerSC>
            ) : bills.allBills?.length === 0 ? (
              <NoData message="Trenutno nema računa." />
            ) : bills.allBills?.length > 0 ? (
              <>
                <BillListSettingsSC>
                  <TopPartSC>
                    <Button
                      colbg="primary500"
                      coltext="neutral100"
                      endIcon={<IconRuleSC />}
                      onClick={handlePayAllButton}
                      disabled={totalKunaDebt === 0}
                    >
                      <span style={{ minWidth: "54px" }}>
                        {payment.isLoadingPayAll ? (
                          <CircularProgress
                            size={20}
                            sx={{ color: "white" }}
                            thickness={6}
                          />
                        ) : (
                          "Plati sve"
                        )}
                      </span>
                    </Button>
                    <TotalDebtSC>
                      <p>Ukupan dug</p>
                      <div>
                        <p className="hrk">
                          <Number
                            className={
                              totalEuroDebt === 0 ? "hrk-num" : "hrk-num unpaid"
                            }
                            value={totalEuroDebt}
                            decimalScale={2}
                          />
                          <span>EUR</span>
                        </p>
                      </div>
                    </TotalDebtSC>
                  </TopPartSC>
                  <BottomPartSC>
                    <FilterButtonSC
                      onClick={handleFilterAll}
                      selected={selectedFilter === "all"}
                    >
                      Svi
                    </FilterButtonSC>
                    <FilterButtonSC
                      onClick={handleFilterUnpaid}
                      selected={selectedFilter === "unpaid"}
                    >
                      Platiti
                    </FilterButtonSC>
                    <FilterButtonSC
                      onClick={handleFilterPaid}
                      selected={selectedFilter === "paid"}
                    >
                      Plaćeni
                    </FilterButtonSC>
                  </BottomPartSC>
                </BillListSettingsSC>
                <BillListSC ref={listRef}>
                  {bills.allTransformedBills?.map((bill) => {
                    return (
                      <BillCardSC
                        key={bill.id}
                        component={NavLink}
                        to={`/racuni/${bill.id}`}
                        onClick={(e) => handleClickCard(e, bill.bill_state)}
                      >
                        <header>
                          <div>
                            <h3>Mjesto potrošnje</h3>
                            <h3>{bill.Device.measuring_place_code}</h3>
                          </div>
                          <div>
                            <h3>Račun</h3>
                            <h3>{fromatBillDateString(bill)}</h3>
                          </div>
                        </header>
                        <BodySC>
                          <DateAndButtonSC>
                            <p>
                              {format(new Date(bill.bill_date), "d.M.yyyy.")}
                            </p>
                            <Button
                              className="pay-button"
                              colbg="danger500"
                              coltext="neutral0"
                              size="small"
                              disabled={
                                bill.bill_state !== "unpaid" ||
                                (payment.billIdLoading &&
                                  payment.billIdLoading !== bill.id)
                              }
                              onClick={() =>
                                handlePayButton(bill.bill_amount_euro, bill.id)
                              }
                            >
                              <span style={{ minWidth: "40px" }}>
                                {bill.bill_state === "unpaid" &&
                                (payment.billIdLoading === bill.id ||
                                  payment.isLoadingPayAll) ? (
                                  <CircularProgress
                                    size={20}
                                    sx={{ color: "white" }}
                                    thickness={6}
                                  />
                                ) : (
                                  "Plati"
                                )}
                              </span>
                            </Button>
                          </DateAndButtonSC>
                          <TotalPriceSC>
                            <p>Ukupno</p>
                            <div>
                              {isBefore(
                                new Date(bill.bill_date),
                                new Date(2023, 1, 1)
                              ) ? (
                                <>
                                  <p className="hrk">
                                    <Number
                                      className={
                                        bill.bill_state === "unpaid"
                                          ? "hrk-num unpaid"
                                          : "hrk-num"
                                      }
                                      value={bill.bill_amount_hrk}
                                      decimalScale={2}
                                    />
                                    <span>HRK</span>
                                  </p>
                                  <p className="eur">
                                    <span className="bracket">(</span>
                                    <Number
                                      className="eur-num"
                                      value={bill.bill_amount_euro}
                                      decimalScale={2}
                                    />
                                    <span>EUR</span>
                                    <span className="bracket">)</span>
                                  </p>
                                </>
                              ) : (
                                <>
                                  <p className="hrk">
                                    <Number
                                      className={
                                        bill.bill_state === "unpaid"
                                          ? "hrk-num unpaid"
                                          : "hrk-num"
                                      }
                                      value={bill.bill_amount_euro}
                                      decimalScale={2}
                                    />
                                    <span>EUR</span>
                                  </p>
                                  <p className="eur">
                                    <span className="bracket">(</span>
                                    <Number
                                      className="eur-num"
                                      value={bill.bill_amount_hrk}
                                      decimalScale={2}
                                    />
                                    <span>HRK</span>
                                    <span className="bracket">)</span>
                                  </p>
                                </>
                              )}
                            </div>
                          </TotalPriceSC>
                        </BodySC>
                      </BillCardSC>
                    );
                  })}
                </BillListSC>
              </>
            ) : null}
          </LeftPageSideSC>
        )}
      </Drawer>
      {(location.pathname.startsWith("/racuni/") || matches501Up) && (
        <RightPageSideSC>
          <RightPageSideContainerSC>
            {matches501Up && !matches1024Up && (
              <ButtonBillsBackSC onClick={toggleDrawer}>
                <ArrowBackSC />
                Računi
              </ButtonBillsBackSC>
            )}
            {!matches501Up && (
              <ButtonBillsBackSC onClick={handleBillsBackLink}>
                <ArrowBackSC />
                Računi
              </ButtonBillsBackSC>
            )}
            <Outlet />
          </RightPageSideContainerSC>
        </RightPageSideSC>
      )}
    </PageWrapperSC>
  );
});

export default Bills;
