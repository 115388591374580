import { view } from "@risingstack/react-easy-state";

import { devices } from "../../stores/devices";

import Toggler from "../../parts/Toggler/Toggler";

import { OnlineBillBoxSC } from "./styled";
import { bills } from "../../stores/bills";

const OnlineBill = view(() => {
  const handleChangeToggler = async () => {
    await devices.updateDevice(bills.bill.Device.id, !bills.bill.Device.e_bill);
    if (devices.isUpdateSuccessfull) {
      bills.allBills
        .filter((b) => b.Device.id === bills.bill.Device.id)
        .forEach((b) => (b.Device.e_bill = !bills.bill.Device.e_bill));
    }
    devices.isUpdateSuccessfull = false;
  };

  return (
    <>
      <OnlineBillBoxSC>
        <div>
          <h3 onClick={handleChangeToggler}>Online račun</h3>
          <p>
            {bills.bill?.Device.e_bill
              ? "Hvala Vam što brinete za okoliš!"
              : "Aktivirajte opciju online dostave računa"}
          </p>
        </div>
        <div>
          <Toggler
            checked={Boolean(bills.bill?.Device.e_bill)}
            onChange={handleChangeToggler}
            disabled={devices.isLoadingUpdate}
          />
        </div>
      </OnlineBillBoxSC>
    </>
  );
});

export default OnlineBill;
