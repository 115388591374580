import { view } from "@risingstack/react-easy-state";
import { Link } from "react-router-dom";

import useMediaQuery from "@mui/material/useMediaQuery";

import { auth } from "../../stores/auth";

import Button from "../../parts/Button/Button";

import {
  WrapperSC,
  LeftPageSideSC,
  TitlePrimarySC,
  LogoFullSC,
  SubsectionSC,
  SubtitleSC,
  ParagraphSC,
  RightPageSideSC,
  IconInfoLargeSC,
  IconInfoCircleSC,
  IconArrowBackSC,
  TitleSecondarySC,
  InfoContentSC,
  InfoContentInnerSC,
  ButtonWrapperSC,
  ButtonSmallScreenWrapperSC,
} from "./styled";

const Info = view(() => {
  const matches800Up = useMediaQuery("(min-width:800px)");

  return (
    <WrapperSC>
      <LeftPageSideSC>
        <TitlePrimarySC>Impressum</TitlePrimarySC>
        <LogoFullSC />
        <SubsectionSC>
          <SubtitleSC>Miholjački vodovod d.o.o</SubtitleSC>
          <ParagraphSC>Pavla Radića 99</ParagraphSC>
          <ParagraphSC>31540 Donji Miholjac</ParagraphSC>
          <ParagraphSC>OIB: 30605443172</ParagraphSC>
          <ParagraphSC>
            Tel. <a href="tel:+38531631507">(031) 631 - 507</a>
          </ParagraphSC>
          <ParagraphSC>
            Fax. <a href="tel:+38531630973">(031) 630 - 973</a>
          </ParagraphSC>
          <ParagraphSC>
            e-mail:{" "}
            <a href="mailto:info@miholjacki-vodovod.hr">
              info@miholjacki-vodovod.hr
            </a>
          </ParagraphSC>
        </SubsectionSC>
        <SubsectionSC>
          <SubtitleSC>Direktor:</SubtitleSC>
          <ParagraphSC>Dragana Pnjak, dipl.ing.</ParagraphSC>
          <ParagraphSC>e-mail:</ParagraphSC>
          <ParagraphSC>
            <a href="mailto:dragana.pnjak@miholjacki-vodovod.hr">
              dragana.pnjak@miholjacki-vodovod.hr
            </a>
          </ParagraphSC>
        </SubsectionSC>
        <SubsectionSC>
          <SubtitleSC>
            Voditelj službe za financijsko-računovodstvene poslove:
          </SubtitleSC>
          <ParagraphSC>Matija Vidaković</ParagraphSC>
          <ParagraphSC>
            e-mail:{" "}
            <a href="mailto:matija@miholjacki-vodovod.hr">
              matija@miholjacki-vodovod.hr
            </a>
          </ParagraphSC>
        </SubsectionSC>
        <SubsectionSC>
          <SubtitleSC>Financijski knjigovođa:</SubtitleSC>
          <ParagraphSC>Damir Skeledžija</ParagraphSC>
          <ParagraphSC>
            Tel. <a href="tel:+38531630946">(031) 630 - 946</a>
          </ParagraphSC>
          <ParagraphSC>
            e-mail:{" "}
            <a href="mailto:damir@miholjacki-vodovod.hr">
              damir@miholjacki-vodovod.hr
            </a>
          </ParagraphSC>
        </SubsectionSC>
        <SubsectionSC>
          <SubtitleSC>Saldo-konti kupaca:</SubtitleSC>
          <ParagraphSC>Ljekra Hrupački</ParagraphSC>
          <ParagraphSC>
            e-mail:{" "}
            <a href="mailto:ljerka@miholjacki-vodovod.hr">
              ljerka@miholjacki-vodovod.hr
            </a>
          </ParagraphSC>
        </SubsectionSC>
        <SubsectionSC>
          <SubtitleSC>Djelatnost poduzeća:</SubtitleSC>
          <ParagraphSC>vodoopskrba, odvodnja</ParagraphSC>
        </SubsectionSC>
        {!auth.isLoggedIn && !matches800Up && (
          <ButtonSmallScreenWrapperSC>
            <Button
              colbg="primary500"
              coltext="primary50"
              component={Link}
              to="/prijava"
              endIcon={<IconArrowBackSC />}
            >
              Povratak na prijavu
            </Button>
          </ButtonSmallScreenWrapperSC>
        )}
      </LeftPageSideSC>
      <RightPageSideSC>
        <IconInfoLargeSC />
        <div>
          <TitleSecondarySC>Informacije o aplikaciji</TitleSecondarySC>
          <InfoContentSC>
            <IconInfoCircleSC />
            <InfoContentInnerSC>
              <ParagraphSC color="black">
                Aplikacija <strong>"Miholjački vodovod"</strong> omogućava
                korisnicima:
              </ParagraphSC>
              <ul>
                <li>
                  <ParagraphSC color="black">
                    pregled potrošnje vodnih usluga
                  </ParagraphSC>
                </li>
                <li>
                  <ParagraphSC color="black">slanje upita</ParagraphSC>
                </li>
                <li>
                  <ParagraphSC color="black">
                    plaćanja obveza za vodne usluge
                  </ParagraphSC>
                </li>
                <li>
                  <ParagraphSC color="black">
                    uvid u račune za vodne usluge u elektroničkom obliku
                  </ParagraphSC>
                </li>
              </ul>
              <ParagraphSC color="black">
                O novim mogućnostima i nadogradnjama pravodobno ćemo Vas
                izvijestiti.
              </ParagraphSC>
              {!auth.isLoggedIn && (
                <ButtonWrapperSC>
                  <Button
                    colbg="primary500"
                    coltext="primary50"
                    component={Link}
                    to="/prijava"
                    endIcon={<IconArrowBackSC />}
                  >
                    Povratak na prijavu
                  </Button>
                </ButtonWrapperSC>
              )}
            </InfoContentInnerSC>
          </InfoContentSC>
        </div>
      </RightPageSideSC>
    </WrapperSC>
  );
});

export default Info;
